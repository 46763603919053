import { Component, OnInit } from '@angular/core';
import { PhaseService } from '../phase.service';
import { Router } from '@angular/router';
import { LanguageService } from '../../../services/language.service';
import { WorkflowGuidesService } from '../../workflowguides.service';
import { Workflow } from '@backend/api/WorkflowGuides/Workflow/workflow.model';
import { SpinnerService } from '@backend/webapp/spinner/spinner.service';

@Component({
  selector: 'backend-edit-phase-wrapper',
  templateUrl: './edit-phase-wrapper.component.html',
  styleUrls: ['./edit-phase-wrapper.component.scss'],
})
export class EditPhaseWrapperComponent implements OnInit {
  public isEditMode = false;

  public id: number;
  public key: string;
  public name: string;
  private versionDescription: string;
  private copyFrom: number;
  private ar: boolean;
  public isPhaseNameValid = true;
  public editPhase: any;
  public phaseWrapperList: any[];
  public phaseList: any[];
  public copyExistingPhase: false;
  public workflowList: any[];

  public constructor(
    private readonly router: Router,
    private readonly phaseService: PhaseService,
    public readonly languageService: LanguageService,
    public readonly linksService: WorkflowGuidesService,
    public readonly spinnerService: SpinnerService
  ) {}

  public async ngOnInit(): Promise<void> {
    this.spinnerService.showSpinner();
    this.phaseWrapperList = [];
    this.phaseList = [];
    await this.linksService.loadWfLinks();
    this.linksService.wflinks.subscribe({
      next: (value) => {
        this.workflowList = value;
      },
    });
    this.resetFields();
    this.updateFields();
    this.spinnerService.hideSpinner();
  }

  private async updateFields(): Promise<void> {
    const phaseWrapper = (await this.phaseService.getPhaseWrapper()) as any;
    this.editPhase = phaseWrapper;
    if (phaseWrapper) {
      this.id = phaseWrapper.id;
      this.key = phaseWrapper.key;
      this.name = phaseWrapper.name;
      this.isEditMode = true;
    } else {
      this.resetFields();
      this.isEditMode = false;
    }
  }

  private resetFields(): void {
    this.id = null;
    this.key = '';
    this.name = '';
    this.ar = false;
    this.copyFrom = -1;
    this.versionDescription = '';
    this.phaseWrapperList = [];
    this.phaseList = [];
  }

  public async save() {
    let result: boolean;
    if (!this.isEditMode) {
      result = await this.phaseService.createPhaseWrapper(
        this.key,
        this.name,
        this.copyFrom,
        this.versionDescription,
        this.ar
      );
    } else {
      result = await this.phaseService.editPhaseWrapper(this.id, this.name);
    }

    if (result) {
      this.gotoPhaseWrappers();
      this.linksService.loadPhaseLinks();
      this.linksService.loadWfLinks();
    }
  }

  public async copyPhaseWrapperName(phaseWrapper: any) {
    this.name = phaseWrapper.name;
    this.key = phaseWrapper.key;
    this.versionDescription = this.phaseList.find(
      (x) => x.phaseWrapper.id == phaseWrapper.id
    ).versionDescription;
    this.ar = this.phaseList.find(
      (x) => x.phaseWrapper.id == phaseWrapper.id
    ).ar;
    this.copyFrom = this.phaseList.find(
      (x) => x.phaseWrapper.id == phaseWrapper.id
    ).id;
  }

  public async cancel() {
    this.gotoPhaseWrappers();
  }

  public gotoPhaseWrappers(): void {
    this.router.navigate(['workflowguides/phases']);
  }

  public async checkForValidPhaseName(phaseName: string): Promise<void> {
    this.name = phaseName?.trim();
    if (this.isEditMode && this.editPhase) {
      if (
        this.name.toLowerCase() === this.editPhase.name.trim().toLowerCase()
      ) {
        this.isPhaseNameValid = true;
        return;
      }
    }

    if (!this.phaseWrapperList) {
      return;
    }

    this.isPhaseNameValid = this.phaseWrapperList?.find(
      (phase) => phase.name.trim()?.toLowerCase() === this.name?.toLowerCase()
    )
      ? false
      : true;
  }

  public GetPhasesForSelectedWorkFlow(workFlow: Workflow) {
    const workflow = this.workflowList.find((x) => x.id === workFlow.id);
    const phaseList =
      workflow?.workflowReleaseVersion?.phasen ??
      workflow?.workflowDraftVersion?.phasen ??
      [];
    phaseList.forEach((element) => {
      this.phaseWrapperList.push(element.phaseWrapper);
      this.phaseList.push(element);
    });
  }
}
