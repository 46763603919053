<sh-page>
  <sh-pane slot="left" behavior="collapse">
    <sh-accordion label="Workflows" expanded>
      <ng-container
      *ngIf="router.url.indexOf('/workflows') !== -1"
      style="overflow-x: scroll"
      >
      <sh-menu-item
        style="max-height: fit-content; position: relative"
        *ngFor="let workflow of workflowLinks | async"
        [label]="workflow.title | getLocaleText : globalLanguage?.key : true"
        [active]="isActive('workflow', workflow?.id)"
        [expanded]="this.paramsService.workflowId === workflow.id"
        [toggle]="false"
        (click)="popoverService.preventDefault($event);
                popoverService.goToRoute(
                  this.popovers,
                  ['workflows', workflow.id, 'versions', workflow.workflowDraftVersion ? workflow.workflowDraftVersion?.id : workflow.workflowReleasedVersion?.id],true)"
        >
        <div id="clearfix">
          <ng-container *ngIf="this.paramsService.workflowId === workflow.id">
            <sh-menu-item
              *ngFor="let version of workflow.versions"
              label=""
              [active]="isActive('workflowVersion', version?.id)"
              (click)="popoverService.preventDefault($event);
                popoverService.goToRoute(
                  this.popovers,
                  ['workflows', workflow.id, 'versions', version.id],
                  true
                )
              "
              [expanded]="this.paramsService.workflowVersionId === version.id"
            >
              <sh-badge
                slot="functions"
                style="float: right; position: absolute;"
                [ngClass]="{'draft': version.status === workflow.workflowDraftVersion?.status}"
                [label]="version.status === workflow.workflowDraftVersion?.status ? 'DRAFT' : 'RELEASE'"
              ></sh-badge>
              <div id="clearfix">
                <ng-container
                  *ngIf="this.paramsService.workflowVersionId === version.id"
                >
                  <sh-menu-item
                    *ngFor="
                      let phase of sortByPhaseOrder(
                        version.phasen,
                        version.phaseOrder
                      )
                    "
                    [label]="
                      phase?.name | getLocaleText : globalLanguage?.key : true
                    "
                    (click)="
                      popoverService.preventDefault($event);
                      popoverService.goToRoute(
                        this.popovers,
                        [
                          'workflows',
                          workflow?.id,
                          'versions',
                          version?.id,
                          'phases',
                          phase?.phaseWrapper?.id,
                          'versions',
                          phase?.id
                        ],
                        true
                      )
                    "
                    [active]="isActive('phaseVersion', phase?.id)"
                    [expanded]="this.paramsService.phaseVersionId === phase?.id"
                  >
                    <div id="clearfix">
                      <ng-container
                        *ngIf="this.paramsService.phaseVersionId === phase?.id"
                      >
                        <sh-menu-item
                          *ngFor="
                            let keyActivity of sortByOrder(phase?.keyActivities)
                          "
                          [label]="
                            keyActivity.title
                              | getLocaleText : globalLanguage?.key : true
                          "
                          (click)="
                            popoverService.preventDefault($event);
                            popoverService.goToRoute(
                              this.popovers,
                              [
                                'workflows',
                                workflow.id,
                                'versions',
                                version.id,
                                'phases',
                                phase.phaseWrapper?.id,
                                'versions',
                                phase.id,
                                'keyactivities',
                                keyActivity.id
                              ],
                              true
                            )
                          "
                          [active]="isActive('keyActivity', keyActivity?.id)"
                          [expanded]="
                            this.paramsService.keyActivityId === keyActivity.id
                          "
                        >
                          <div>
                            <ng-container
                              *ngIf="
                                this.paramsService.keyActivityId ===
                                keyActivity.id
                              "
                            >
                              <sh-menu-item
                                *ngFor="
                                  let step of sortByOrder(keyActivity.steps)
                                "
                                [label]="step.key"
                                (click)="
                                  popoverService.preventDefault($event);
                                  popoverService.goToRoute(
                                    this.popovers,
                                    [
                                      'workflows',
                                      workflow.id,
                                      'versions',
                                      version.id,
                                      'phases',
                                      phase.phaseWrapper?.id,
                                      'versions',
                                      phase.id,
                                      'keyactivities',
                                      keyActivity.id,
                                      'steps',
                                      step.id
                                    ],
                                    true
                                  )
                                "
                                [active]="isActive('step', step?.id)"
                                [expanded]="
                                  this.paramsService.stepId === step.id
                                "
                              >
                              </sh-menu-item>
                            </ng-container>
                          </div>
                        </sh-menu-item>
                      </ng-container>
                    </div>
                  </sh-menu-item>
                </ng-container>
              </div>
            </sh-menu-item>
          </ng-container>
        </div>
      </sh-menu-item>
    </ng-container>
    <ng-container
      *ngIf="
        router.url.indexOf('/workflows') === -1 &&
        router.url.indexOf('/phases') !== -1
      "
    >
      <sh-menu-item
        style="max-height: fit-content"
        *ngFor="let phasewWrapper of phaseLinks | async"
        [label]="phasewWrapper.name"
        [active]="isActive('phase', phasewWrapper?.id)"
        (click)="
          popoverService.goToRoute(
            this.popovers,
            ['phases', phasewWrapper.id, 'versions'],
            true
          )
        "
        [expanded]="this.paramsService.phaseWrapperId === phasewWrapper.id"
      >
        <div id="clearfix">
          <ng-container
            *ngIf="this.paramsService.phaseWrapperId === phasewWrapper.id"
          >
            <sh-menu-item
              *ngFor="let phase of phasewWrapper.versions"
              [label]="phase.versionDescription"
              [active]="isActive('phaseVersion', phase?.id)"
              (click)="
                popoverService.preventDefault($event);
                popoverService.goToRoute(
                  this.popovers,
                  ['phases', phasewWrapper.id, 'versions', phase.id],
                  true
                )
              "
              [expanded]="this.paramsService.phaseVersionId === phase.id"
            >
              <div id="clearfix">
                <ng-container
                  *ngIf="this.paramsService.phaseVersionId === phase.id"
                >
                  <sh-menu-item
                    *ngFor="let keyActivity of sortByOrder(phase.keyActivities)"
                    [label]="
                      keyActivity.title
                        | getLocaleText : globalLanguage?.key : true
                    "
                    (click)="
                      popoverService.preventDefault($event);
                      popoverService.goToRoute(
                        this.popovers,
                        [
                          'phases',
                          phasewWrapper.id,
                          'versions',
                          phase.id,
                          'keyactivities',
                          keyActivity.id
                        ],
                        true
                      )
                    "
                    [active]="isActive('keyActivity', keyActivity?.id)"
                    [expanded]="
                      this.paramsService.keyActivityId === keyActivity.id
                    "
                  >
                    <div>
                      <ng-container
                        *ngIf="
                          this.paramsService.keyActivityId === keyActivity.id
                        "
                      >
                        <sh-menu-item
                          *ngFor="let step of sortByOrder(keyActivity.steps)"
                          [label]="step.key"
                          (click)="
                            popoverService.preventDefault($event);
                            popoverService.goToRoute(
                              this.popovers,
                              [
                                'phases',
                                phasewWrapper.id,
                                'versions',
                                phase.id,
                                'keyactivities',
                                keyActivity.id,
                                'steps',
                                step.id
                              ],
                              true
                            )
                          "
                          [active]="isActive('step', step?.id)"
                          [expanded]="this.paramsService.stepId === step.id"
                        >
                        </sh-menu-item>
                      </ng-container>
                    </div>
                  </sh-menu-item>
                </ng-container>
              </div>
            </sh-menu-item>
          </ng-container>
        </div>
      </sh-menu-item>
    </ng-container>
    </sh-accordion>
  </sh-pane>
  <div columns="9" class="routerOutlet">
    <router-outlet></router-outlet>
  </div>
</sh-page>
