<div>
  <sh-table cdkDropList (cdkDropListDropped)="drop($event)">
    <sh-table-row>
      <sh-table-head
        unsortable
        columns="14"
        [innerHTML]="
          'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.title'
            | translate
        "
      ></sh-table-head>
      <sh-table-head
        unsortable
        columns="8"
        [innerHTML]="
          'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.countSteps'
            | translate
        "
      ></sh-table-head>
      <sh-table-head unsortable icon></sh-table-head>
    </sh-table-row>
    <sh-table-row
      *ngFor="let keyActivity of keyActivities"
      cdkDrag
      cdkDragLockAxis="y"
    >
      <sh-table-cell
        columns="14"
        [routerLink]="['keyactivities', keyActivity.id]"
        cdkDragHandle
      >
        <p class="flow-text">
          {{ keyActivity.title | getLocaleText : globalLanguage?.key : true }}
        </p>
      </sh-table-cell>
      <sh-table-cell
        columns="8"
        [routerLink]="['keyactivities', keyActivity.id]"
        cdkDragHandle
      >
        <p class="flow-text">{{ keyActivity.steps?.length }}</p>
      </sh-table-cell>
      <sh-table-cell
        icon
        (click)="deleteKeyActivity(keyActivity.id, keyActivity.title)"
        cdkDragHandle
      >
        <sh-icon button *ngIf="!linksService.isReleaseVersionActive()" icon="delete"></sh-icon>
      </sh-table-cell>
      <div class="placeholder" *cdkDragPlaceholder></div>
    </sh-table-row>
  </sh-table>

  <div class="buttons" *ngIf="!linksService.isReleaseVersionActive()">
    <sh-button
      size="m"
      [label]="'general.add' | translate"
      (click)="addKeyActivity()"
    ></sh-button>
  </div>
</div>
