<div>
  <sh-nav-bar slot="nav">
    <sh-tabs>
      <sh-tab-item
        [label]="
          'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.assets.tabBase'
            | translate
        "
        [active]="section === 'base'"
        (click)="section = 'base'"
      ></sh-tab-item>
      <sh-tab-item
        [label]="
          'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.assets.tabInstructions'
            | translate
        "
        [active]="section === 'instructions'"
        [disabled]="!id"
        *ngIf="assetType === 'instructions'"
        (click)="section = 'instructions'"
      ></sh-tab-item>
      <sh-tab-item
        [label]="
          'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.assets.tabHotspots'
            | translate
        "
        [active]="section === 'hotspots'"
        [disabled]="
          !id || assetType === 'simulation' || assetType === 'instructions'
        "
        (click)="section = 'hotspots'"
      ></sh-tab-item>
    </sh-tabs>
  </sh-nav-bar>
  <div [style.display]="section === 'base' ? 'contents' : 'none'">
    <div
      class="content"
      [style.display]="assetType === 'image' ? 'flex' : 'none'"
    >
      <ng-container *ngTemplateOutlet="targetTemplate"></ng-container>
      <div class="content-row">
        <sh-text
          size="header-3"
          [innerHTML]="
            'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.assets.path'
              | translate
          "
        ></sh-text>
        <sh-input-text
          [disabled]="workflowGuidesService.isReleaseVersionActive()"
          mandatory
          readonly
          [disabled]="true"
          [value]="asset"
          [label]="
            'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.assets.path'
              | translate
          "
        ></sh-input-text>
        <sh-button
          *ngIf="!workflowGuidesService.isReleaseVersionActive()"
          size="m"
          [label]="'general.openFile' | translate"
          (click)="inputFile.click()"
        ></sh-button>
      </div>
      <div class="content-row">
        <sh-text size="header-3"></sh-text>
        <img *ngIf="asset && !assetChanged" [src]="asset | s3Image | async" />
        <img #previewImage *ngIf="assetChanged" />
      </div>
      <div class="content-row">
        <sh-text
          size="header-3"
          [innerHTML]="
            'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.assets.canFade'
              | translate
          "
        ></sh-text>
        <sh-checkbox
          [disabled]="workflowGuidesService.isReleaseVersionActive()"
          [label]="''"
          [active]="canFadeInitial"
          (click)="canFade = !canFade"
        ></sh-checkbox>
      </div>
    </div>

    <div
      class="content"
      [style.display]="assetType === 'video' ? 'flex' : 'none'"
    >
      <ng-container *ngTemplateOutlet="targetTemplate"></ng-container>
      <div class="content-row">
        <sh-text
          size="header-3"
          [innerHTML]="
            'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.assets.path'
              | translate
          "
        ></sh-text>
        <sh-input-text
          [disabled]="workflowGuidesService.isReleaseVersionActive()"
          mandatory
          readonly
          [disabled]="true"
          [value]="asset"
          [label]="
            'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.assets.path'
              | translate
          "
        ></sh-input-text>
        <sh-button
          [disabled]="workflowGuidesService.isReleaseVersionActive()"
          size="m"
          [label]="'general.openFile' | translate"
          (click)="inputFile.click()"
        ></sh-button>
      </div>
      <div class="content-row">
        <sh-text
          size="header-3"
          [innerHTML]="
            'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.assets.canSeek'
              | translate
          "
        ></sh-text>
        <sh-checkbox
          [disabled]="workflowGuidesService.isReleaseVersionActive()"
          [label]="''"
          [active]="canSeekInitial"
          (checked)="canSeek = !canSeek"
        ></sh-checkbox>
      </div>
      <div class="content-row">
        <sh-text
          size="header-3"
          [innerHTML]="
            'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.assets.startAt'
              | translate
          "
        ></sh-text>
        <sh-input-number
          [disabled]="workflowGuidesService.isReleaseVersionActive()"
          class="time-picker"
          *ngIf="startAt != null"
          [label]="'ms'"
          min="0"
          no-arrows
          [value]="this.startAt"
          (value-changed)="this.startAt = $event.target.value"
        ></sh-input-number>
      </div>
      <div class="content-row">
        <sh-text
          size="header-3"
          [innerHTML]="
            'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.assets.endAt'
              | translate
          "
        ></sh-text>
        <sh-input-number
          [disabled]="workflowGuidesService.isReleaseVersionActive()"
          *ngIf="endAt != null"
          [label]="'ms'"
          min="0"
          no-arrows
          [value]="endAt"
          (value-changed)="this.endAt = $event.target.value"
        ></sh-input-number>
      </div>
    </div>

    <div
      class="content"
      [style.display]="assetType === 'simulation' ? 'flex' : 'none'"
    >
      <ng-container *ngTemplateOutlet="targetTemplate"></ng-container>
      <div class="content-row">
        <sh-text
          size="header-3"
          [innerHTML]="
            'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.assets.cameraTag'
              | translate
          "
        ></sh-text>
        <sh-input-text
          [disabled]="workflowGuidesService.isReleaseVersionActive()"
          [value]="cameraTag"
          [label]="
            'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.assets.cameraTag'
              | translate
          "
          (value-changed)="cameraTag = $event.target.value"
        ></sh-input-text>
      </div>
    </div>

    <div
      class="content"
      [style.display]="assetType === 'instructions' ? 'flex' : 'none'"
    >
      <ng-container *ngTemplateOutlet="targetTemplate"></ng-container>
      <div class="content-row">
        <sh-text
          size="header-3"
          [innerHTML]="
            'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.assets.indent'
              | translate
          "
        ></sh-text>
        <sh-input-text
          [disabled]="workflowGuidesService.isReleaseVersionActive()"
          [value]="indent"
          [label]="
            'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.assets.indent'
              | translate
          "
          (value-changed)="indent = $event.target.value"
        ></sh-input-text>
      </div>
      <div class="content-row">
        <sh-text
          size="header-3"
          [innerHTML]="
            'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.assets.displayInterval'
              | translate
          "
        ></sh-text>
        <sh-input-number
          [disabled]="workflowGuidesService.isReleaseVersionActive()"
          [label]="
            'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.assets.displayInterval'
              | translate
          "
          [value]="displayInterval"
          (value-changed)="displayInterval = $event.target.value"
        ></sh-input-number>
      </div>
      <div class="content-row">
        <sh-text
          size="header-3"
          [innerHTML]="
            'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.assets.fadeInDuration'
              | translate
          "
        ></sh-text>
        <sh-input-number
          [disabled]="workflowGuidesService.isReleaseVersionActive()"
          [label]="
            'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.assets.fadeInDuration'
              | translate
          "
          [value]="fadeInDuration"
          (value-changed)="fadeInDuration = $event.target.value"
        ></sh-input-number>
      </div>
      <div class="content-row">
        <sh-text
          size="header-3"
          [innerHTML]="
            'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.assets.listType'
              | translate
          "
        ></sh-text>
        <sh-input-text
          [disabled]="workflowGuidesService.isReleaseVersionActive()"
          [value]="listType"
          [label]="
            'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.assets.listType'
              | translate
          "
          (value-changed)="listType = $event.target.value"
        ></sh-input-text>
      </div>
    </div>
  </div>

  <div [style.display]="section === 'instructions' ? 'contents' : 'none'">
    <div class="content">
      <div
        class="content-row"
        *ngFor="let formControl of instructionFormControls; index as i"
      >
        <sh-text
          size="header-3"
          [innerHTML]="
            ('workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.assets.instruction'
              | translate) +
            ' ' +
            (i + 1)
          "
        ></sh-text>
        <sh-input-text
          [disabled]="workflowGuidesService.isReleaseVersionActive()"
          [value]="instructionsTrans[i]"
          (value-changed)="setValue(formControl, $event.target.value, i)"
          textarea
          [label]="
            'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.assets.instructionText'
              | translate
          "
        ></sh-input-text>
        <sh-button
          *ngIf="!workflowGuidesService.isReleaseVersionActive()"
          size="m"
          [label]="'general.delete' | translate"
          (click)="removeInstruction(i)"
        ></sh-button>
      </div>
      <sh-button 
        *ngIf="!workflowGuidesService.isReleaseVersionActive()"
        size="m"
        [label]="'general.add' | translate"
        (click)="addInstruction()"
      ></sh-button>
    </div>
  </div>

  <div [style.display]="section === 'hotspots' ? 'block' : 'none'">
    <backend-hotspots
      [Asset]="asset"
      [assetType]="assetType"
    ></backend-hotspots>
  </div>

  <div class="buttons" *ngIf="section !== 'hotspots'" >
    <sh-button
      *ngIf="!workflowGuidesService.isReleaseVersionActive()"
      size="m"
      [disabled]="!canSave()"
      (click)="save()"
      [label]="'general.save' | translate"
    ></sh-button>
    <sh-button
      *ngIf="!workflowGuidesService.isReleaseVersionActive()"
      color="secondary"
      (click)="cancel()"
      size="m"
      [label]="'general.cancel' | translate"
    ></sh-button>
  </div>
</div>

<input
  #inputFile
  type="file"
  [accept]="
    assetType === 'image' ? '.jpg,.jpeg,.png' : '.mov,.mpg,.mpeg,.mp4,.avi,.asf'
  "
  style="display: none"
  (change)="fileChange($event.target['files'][0])"
/>

<ng-template #targetTemplate>
  <div class="content-row">
    <sh-text
      size="header-3"
      [innerHTML]="
        'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.assets.target'
          | translate
      "
    ></sh-text>
    <sh-input-text
      [disabled]="workflowGuidesService.isReleaseVersionActive()"
      mandatory
      [value]="target"
      [label]="
        'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.assets.target'
          | translate
      "
      (value-changed)="target = $event.target.value"
    ></sh-input-text>
  </div>
</ng-template>
