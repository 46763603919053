<div>
  <sh-nav-bar slot="nav">
  <sh-tabs>
    <sh-tab-item
      [label]="
        'workflowguides.phases.phaseWrappers.phaseVersions.tabBase' | translate
      "
      [active]="section === 'base'"
      (click)="section = 'base'"
    ></sh-tab-item>
    <sh-tab-item
      [label]="
        'workflowguides.phases.phaseWrappers.phaseVersions.tabKeyActivities'
          | translate
      "
      [active]="section === 'keyActivities'"
      [disabled]="!id"
      (click)="section = 'keyActivities'"
    ></sh-tab-item>
  </sh-tabs>
  </sh-nav-bar>
  <div class="content" [style.display]="section === 'base' ? 'flex' : 'none'">
    <div class="content-row">
      <sh-text
        size="header-3"
        [innerHTML]="
          'workflowguides.phases.phaseWrappers.phaseVersions.name' | translate
        "
      ></sh-text>
      <sh-input-text
        mandatory
        [disabled]="linksService.isReleaseVersionActive()"
        [label]="
          'workflowguides.phases.phaseWrappers.phaseVersions.name' | translate
        "
        [value]="nameTrans"
        (value-changed)="name = $event.target.value"
      ></sh-input-text>
    </div>

    <div class="content-row">
      <sh-text
        size="header-3"
        [innerHTML]="
          'workflowguides.phases.phaseWrappers.phaseVersions.description'
            | translate
        "
      ></sh-text>
      <sh-input-text
        mandatory
        [disabled]="linksService.isReleaseVersionActive()"
        [label]="
          'workflowguides.phases.phaseWrappers.phaseVersions.description'
            | translate
        "
        [value]="descriptionTrans"
        (value-changed)="description = $event.target.value"
      ></sh-input-text>
    </div>

    <div class="content-row">
      <sh-text
        size="header-3"
        [innerHTML]="
          'workflowguides.phases.phaseWrappers.phaseVersions.ar' | translate
        "
      ></sh-text>
      <sh-checkbox
        [active]="arInitial"
        [disabled]="linksService.isReleaseVersionActive()"
        [label]="''"
        (click)="ar = !ar"
      ></sh-checkbox>
    </div>

    <div class="content-row">
      <sh-text
        [innerHTML]="
          'workflowguides.phases.phaseWrappers.phaseVersions.thumbnail'
            | translate
        "
      ></sh-text>
      <sh-input-text
        mandatory
        readonly
        [value]="thumbnailBasename"
        [label]="
          'workflowguides.phases.phaseWrappers.phaseVersions.thumbnail'
            | translate
        "
      ></sh-input-text>
      <sh-button
        size="m"
        [disabled]="linksService.isReleaseVersionActive()"
        [label]="'general.openFile' | translate"
        (click)="inputFile.click()"
      ></sh-button>
    </div>
    <div class="content-row">
      <sh-text size="header-3"></sh-text>
      <img
        *ngIf="thumbnail && !thumbnailChanged"
        [src]="thumbnail | s3Image | async"
      />
      <img #previewImage *ngIf="thumbnailChanged" />
    </div>

    <div class="content-row" *ngIf="!isEditMode">
      <sh-text
        size="header-3"
        [innerHTML]="
          'workflowguides.phases.phaseWrappers.phaseVersions.copyPhaseVersion'
            | translate
        "
      ></sh-text>
      <sh-dropdown
        [label]="
          'workflowguides.phases.phaseWrappers.phaseVersions.copyPhaseVersion'
            | translate
        "
        [value]="null"
        (value-changed)="copyFrom = $event.target.value"
      >
        <sh-menu-item
          [label]="
            'workflowguides.phases.phaseWrappers.phaseVersions.noCopy'
              | translate
          "
          [value]="null"
        ></sh-menu-item>
        <sh-divider></sh-divider>
        <ng-container *ngFor="let phase of phases | async">
          <sh-text style="margin-top: 8px" size="title-1">{{
            phase.key
          }}</sh-text>
          <sh-menu-item
            *ngFor="let version of phase.versions"
            [label]="version.name | getLocaleText : globalLanguage?.key : true"
            [value]="version.id"
          ></sh-menu-item>
          <sh-divider></sh-divider>
        </ng-container>
      </sh-dropdown>
    </div>
  </div>

  <div
    class="content"
    [style.display]="section === 'keyActivities' ? 'flex' : 'none'"
  >
    <backend-key-activities></backend-key-activities>
  </div>

  <div class="buttons" *ngIf="!linksService.isReleaseVersionActive()">
    <sh-button
      [disabled]="!name || !description || !thumbnailBasename"
      size="m"
      (click)="save()"
      [label]="'general.save' | translate"
    ></sh-button>
    <sh-button
      color="secondary"
      (click)="cancel()"
      size="m"
      [label]="'general.cancel' | translate"
    ></sh-button>
  </div>
</div>

<input
  #inputFile
  type="file"
  accept=".jpg,.jpeg,.png"
  style="display: none"
  (change)="fileChange($event.target['files'][0])"
/>
