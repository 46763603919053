import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { Router } from '@angular/router';
import { PhaseVersionService } from './phase-version.service';
import { LanguageService } from '../../../services/language.service';
import { PopoverService } from '../../../services/popover.service';
import { Status } from '@backend/interfaces';
import { WorkflowGuidesService } from '../../workflowguides.service';
import { ParamsService } from '@backend/webapp/services/params.service';
import { Constants } from '@backend/interfaces';
import { LocalizationService } from '@backend/webapp/shared/localization/localization.service';
import { pairwise } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
  selector: 'backend-phase-versions',
  templateUrl: './phase-versions.component.html',
  styleUrls: ['./phase-versions.component.scss'],
})
export class PhaseVersionsComponent implements OnInit, OnDestroy {
  public phaseVersions: any[];

  @ViewChildren('popover')
  public popovers: QueryList<ElementRef<HTMLElement>>;

  public globalLanguage = Constants.DEFAULT_GLOBAL_LANGUAGE;

  paramSubscription: Subscription;
  languageSubscription: Subscription;

  public constructor(
    public readonly languageService: LanguageService,
    public readonly popoverService: PopoverService,
    public readonly paramsService: ParamsService,
    public readonly phaseVersionService: PhaseVersionService,
    public router: Router,
    public readonly linksService: WorkflowGuidesService,
    public readonly localization: LocalizationService
  ) {}

  public deletePhaseVersion(id: number, name) {
    const resp = this.phaseVersionService.deletePhaseVersion(id, name);
    if (resp) {
      this.phaseVersions = this.phaseVersions.filter((p) => p.id !== id);
    }
  }

  public getStatusString(status: number) {
    return Status[status];
  }

  public async ngOnInit(): Promise<void> {
    this.phaseVersions =
      await this.phaseVersionService.getVersionsOfCurrentPhase();
    this.languageSubscription = this.localization.globalLanguage$.subscribe(
      async (data) => {
        if (this.router.isActive) {
          this.globalLanguage = data;
          this.phaseVersions =
            await this.phaseVersionService.getVersionsOfCurrentPhase();
        }
      }
    );
    this.paramSubscription = this.paramsService.phaseWrapperId$
      .pipe(pairwise())
      .subscribe(async ([previous, current]) => {
        if (previous != null && previous !== current && this.router.isActive) {
          this.phaseVersions =
            await this.phaseVersionService.getVersionsOfCurrentPhase();
        }
      });
  }

  ngOnDestroy() {
    if (this.paramSubscription) {
      this.paramSubscription.unsubscribe();
    }
    if (this.languageSubscription) {
      this.languageSubscription.unsubscribe();
    }
  }
}
