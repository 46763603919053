<sh-card>
  <sh-text
    size="header-2"
    [innerHTML]="
      (isEditMode ? 'devices.editPhase' : 'devices.addPhase') | translate
    "
  ></sh-text>
  <div class="flex-container">
    <div
      class="display-flex-column row"
      style="padding-top: 9px"
      *ngIf="!isEditMode"
    >
      <div class="display-flex-row">
        <sh-text
          size="header-4"
          class="sh-text"
          [innerHTML]="'devices.copyExistingWorkflow' | translate"
        ></sh-text>
        <sh-icon icon="information" size="xs" id="info-2"></sh-icon>
        <sh-tooltip
          label="Phase Name"
          target="info-2"
          placement="top"
          variation="short"
        ></sh-tooltip>
      </div>
      <div class="display-flex-row">
        <sh-dropdown [label]="'devices.copyExistingWorkflow' | translate">
          <sh-menu-item
            *ngFor="let workflow of workflowList"
            [label]="
              workflow.title | getLocaleText : globalLanguage?.key : true
            "
            (click)="GetPhasesForSelectedWorkFlow(workflow)"
          ></sh-menu-item>
        </sh-dropdown>
      </div>
    </div>
    <div
      class="display-flex-column row"
      style="padding-top: 12px"
      *ngIf="!isEditMode"
    >
      <div class="display-flex-row">
        <sh-checkbox
          [label]="'devices.copyExistingPhase' | translate"
          (click)="copyExistingPhase = !copyExistingPhase"
        ></sh-checkbox>
        <sh-icon icon="information" size="xs" id="info-1"></sh-icon>
        <sh-tooltip
          label="Copy Existing Phase"
          target="info-1"
          placement="top"
          variation="short"
        ></sh-tooltip>
      </div>
      <div class="display-flex-row">
        <sh-dropdown
          *ngIf="!isEditMode"
          [disabled]="!copyExistingPhase"
          [label]="'devices.copyExistingPhase' | translate"
        >
          <sh-menu-item
            *ngFor="let phase of phaseWrapperList"
            [label]="phase.name"
            (click)="copyPhaseWrapperName(phase)"
          ></sh-menu-item>
        </sh-dropdown>
      </div>
    </div>
    <div class="display-flex-column row">
      <div class="display-flex-row">
        <sh-text
          size="header-4"
          class="sh-text"
          [innerHTML]="
            (isEditMode ? 'devices.phaseName' : 'devices.newPhaseName')
              | translate
          "
        ></sh-text>
        <sh-icon icon="information" size="xs" id="info-2"></sh-icon>
        <sh-tooltip
          label="Workflow Name"
          target="info-2"
          placement="top"
          variation="short"
        ></sh-tooltip>
      </div>
      <div>
        <sh-input-text
          mandatory
          [error]="!isPhaseNameValid"
          error-message="Phase with same name already exists."
          [label]="'devices.newPhaseName' | translate"
          #inputName
          mandatory
          [label]="'workflowguides.workflows.titleCol' | translate"
          [value]="name"
          (value-changed)="checkForValidPhaseName($event.target.value)"
        ></sh-input-text>
      </div>
    </div>
    <div class="display-flex-column row">
      <div class="display-flex-row">
        <sh-text
          size="header-4"
          class="sh-text"
          [innerHTML]="
            'workflowguides.phases.phaseWrappers.key'| translate"
        ></sh-text>
        <sh-icon icon="information" size="xs" id="info-2"></sh-icon>
        <sh-tooltip
          label="Workflow Name"
          target="info-2"
          placement="top"
          variation="short"
        ></sh-tooltip>
      </div>
      <div>
        <sh-input-text
          mandatory
          [label]="'workflowguides.phases.phaseWrappers.key' | translate"
          #inputKey
          mandatory
          [label]="'workflowguides.phases.phaseWrappers.key' | translate"
          [value]="key"
        ></sh-input-text>
      </div>
    </div>
  </div>
  <div class="buttons">
    <sh-button
      [disabled]="!isPhaseNameValid || !inputName.value"
      size="m"
      (click)="save()"
      [label]="'general.save' | translate"
    ></sh-button>
    <sh-button
      color="secondary"
      (click)="cancel()"
      size="m"
      [label]="'general.cancel' | translate"
    ></sh-button>
  </div>
</sh-card>

<!-- <input
  #inputFile
  type="file"
  accept=".jpg,.jpeg,.png"
  style="display: none"
  (change)="fileChange($event.target.files[0])"
/> -->
