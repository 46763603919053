import { Component, OnDestroy, OnInit } from '@angular/core';
import { AssetHotspotGlyph } from '../../../../../../../../../../../api/src/app/WorkflowGuides/PhaseWrapper/Phase/keyActivities/step/asset/hotspot/assetHotspotGlyph.enum';
import { HotspotsService } from '@backend/webapp/workflowguides/phases/phase-versions/key-activities/steps/assets/hotspots/hotspots.service';
import { LanguageService } from '@backend/webapp/services/language.service';
import { Router } from '@angular/router';
import { ParamsService } from '@backend/webapp/services/params.service';
import { LocalizationService } from '@backend/webapp/shared/localization/localization.service';
import { Constants } from '@backend/interfaces';
import { GetLocaleTextPipe } from '@backend/webapp/get-locale-text.pipe';
import { pairwise } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { WorkflowGuidesService } from '@backend/webapp/workflowguides/workflowguides.service';

@Component({
  selector: 'backend-edit-hotspot',
  templateUrl: './edit-hotspot.component.html',
  styleUrls: ['./edit-hotspot.component.scss'],
})
export class EditHotspotComponent implements OnInit, OnDestroy {
  public id: number;
  public isEditMode: boolean;
  public interactable: boolean;
  public interactableInitial: boolean;
  public text: string;
  public hotspotType: string;
  public x = 0;
  public y = 0;
  public glyphValue: string;
  public rotation = 0;
  public width = 0;
  public height = 0;

  public iconGlyphs = Object.keys(AssetHotspotGlyph).filter((k) =>
    Number.isNaN(Number(k))
  );

  public globalLanguage = Constants.DEFAULT_GLOBAL_LANGUAGE;
  textTrans: any[];

  paramSubscription: Subscription;
  languageSubscription: Subscription;

  constructor(
    private readonly hotspotsService: HotspotsService,
    private readonly languageService: LanguageService,
    private readonly paramsService: ParamsService,
    private readonly router: Router,
    public readonly localization: LocalizationService,
    public readonly workflowGuidesService: WorkflowGuidesService
  ) {}

  public async ngOnInit(): Promise<void> {
    this.interactableInitial = this.interactable = false;
    this.isEditMode = this.paramsService.hotspotId != null;
    if (!this.isEditMode) return;
    this.updateHotspot();
    this.languageSubscription = this.localization.globalLanguage$.subscribe(
      async (data) => {
        if (this.router.isActive) {
          this.globalLanguage = data;
          await this.updateHotspot();
        }
      }
    );
    this.paramSubscription = this.paramsService.hotspotId$
      .pipe(pairwise())
      .subscribe(async ([previous, current]) => {
        if (previous != null && previous !== current && this.router.isActive) {
          await this.updateHotspot();
        }
      });
  }

  ngOnDestroy() {
    if (this.paramSubscription) {
      this.paramSubscription.unsubscribe();
    }
    if (this.languageSubscription) {
      this.languageSubscription.unsubscribe();
    }
  }

  async updateHotspot() {
    const hotspot = await this.hotspotsService.get(
      this.paramsService.hotspotId
    );
    if (hotspot) {
      this.id = hotspot.id;
      this.text = this.languageService.getTranslationByKey(
        hotspot.text,
        this.globalLanguage.key
      );
      this.textTrans = new GetLocaleTextPipe(this.localization).transform(
        hotspot.text,
        this.globalLanguage.key
      );
      this.x = hotspot.position?.x;
      this.y = hotspot.position?.y;
      this.width = hotspot.frame?.width;
      this.height = hotspot.frame?.height;
      this.glyphValue = hotspot.icon?.glyph;
      this.rotation = hotspot.icon?.rotation;
      this.interactableInitial = this.interactable = hotspot.interactable;
      this.hotspotType = hotspot.icon ? 'Icon' : 'Frame';
    }
  }

  public async save(): Promise<void> {
    let result: any;

    if (this.isEditMode) {
      result = await this.hotspotsService.edit(this.id, this.interactable);

      if (this.hotspotType === 'Icon') {
        result = await this.hotspotsService.editIcon(
          this.id,
          this.x,
          this.y,
          AssetHotspotGlyph[this.glyphValue],
          this.rotation
        );
      } else {
        result = await this.hotspotsService.editFrame(
          this.id,
          this.x,
          this.y,
          this.width,
          this.height
        );
      }
    } else {
      const hotspot: any = {
        interactable: this.interactable.toString(),
        position_x: Number(this.x),
        position_y: Number(this.y),
      };

      if (this.hotspotType === 'Icon') {
        hotspot.glyph = AssetHotspotGlyph[this.glyphValue];
        hotspot.rotation = Number(this.rotation);
      } else {
        hotspot.width = Number(this.width);
        hotspot.height = Number(this.height);
      }

      result = await this.hotspotsService.create(hotspot);
    }

    if (result && result.id) {
      await this.hotspotsService.editText(
        result.id,
        this.text,
        this.globalLanguage.key
      );
    }

    await this.goBack();
  }

  public async cancel(): Promise<void> {
    await this.goBack();
  }

  private async goBack() {
    if (this.router.url.indexOf('/workflows') !== -1) {
      await this.router.navigate([
        'workflowguides',
        'workflows',
        this.paramsService.workflowId,
        'versions',
        this.paramsService.workflowVersionId,
        'phases',
        this.paramsService.phaseWrapperId,
        'versions',
        this.paramsService.phaseVersionId,
        'keyactivities',
        this.paramsService.keyActivityId,
        'steps',
        this.paramsService.stepId,
        'assets',
        this.paramsService.assetType,
        this.paramsService.assetId,
      ]);
    } else {
      await this.router.navigate([
        'workflowguides',
        'phases',
        this.paramsService.phaseWrapperId,
        'versions',
        this.paramsService.phaseVersionId,
        'keyactivities',
        this.paramsService.keyActivityId,
        'steps',
        this.paramsService.stepId,
        'assets',
        this.paramsService.assetType,
        this.paramsService.assetId,
      ]);
    }
  }

  public canSave(): boolean {
    const canSave = this.hotspotType === 'Icon' || this.hotspotType === 'Frame';
    return (
      canSave &&
      (this.hotspotType === 'Icon'
        ? this.glyphValue?.length > 0 && !Number.isNaN(Number(this.rotation))
        : this.width != null && !Number.isNaN(Number(this.height)))
    );
  }
}
