<div>
  <sh-nav-bar slot="nav">
    <sh-tabs>
      <sh-tab-item
        [label]="'workflowguides.workflows.workflowVersions.tabBase' | translate"
        [active]="section === 'base'"
        (click)="section = 'base'"
      ></sh-tab-item>
      <sh-tab-item
        [label]="
          'workflowguides.workflows.workflowVersions.tabOverviews' | translate
        "
        [active]="section === 'overviews'"
        [disabled]="!id"
        (click)="section = 'overviews'"
      ></sh-tab-item>
      <sh-tab-item
        [label]="
          'workflowguides.workflows.workflowVersions.tabPhases' | translate
        "
        [active]="section === 'phases'"
        [disabled]="!id"
        (click)="section = 'phases'"
      ></sh-tab-item>
    </sh-tabs>
      <sh-button
        style="position: absolute; right: 0; margin: 18px"
        label="Edit"
        *ngIf="workflowGuidesService.isReleaseVersionActive()"
        (click)="createWorkflowVersion()"
      ></sh-button>
  </sh-nav-bar>
  <div class="content">
    <div [style.display]="section === 'base' ? 'block' : 'none'">
      <div class="content-row">
        <sh-text
          size="header-3"
          [innerHTML]="
            'workflowguides.workflows.workflowVersions.deviceVersion'
              | translate
          "
        ></sh-text>
        <sh-dropdown
          [disabled]="workflowGuidesService.isReleaseVersionActive()"
          [label]="
            'workflowguides.workflows.workflowVersions.deviceVersion'
              | translate
          "
          [value]="deviceVersionDetails"
          mandatory
        >
          <ng-container *ngFor="let device of devicesService.devices">
            <sh-text style="margin-top: 8px" size="title-1">{{
              device.deviceDescription.name
                | getLocaleText : globalLanguage?.key : true
            }}</sh-text>
            <sh-menu-item
              *ngIf="device?.deviceReleasedVersion"
              [label]="'general.release' | translate"
              [value]="
                (device.deviceDescription.name
                  | getLocaleText : globalLanguage?.key : true) +
                ' ' +
                ('general.release' | translate)
              "
              (click)="
                setDeviceVersionId(
                  device?.deviceReleasedVersion.id,
                  $event.target.value
                )
              "
            ></sh-menu-item>
            <sh-menu-item
              *ngIf="device?.deviceDraftVersion"
              [label]="'general.draft' | translate"
              [value]="
                (device.deviceDescription.name
                  | getLocaleText : globalLanguage?.key : true) +
                ' ' +
                ('general.draft' | translate)
              "
              (click)="
                setDeviceVersionId(
                  device?.deviceDraftVersion.id,
                  $event.target.value
                )
              "
            ></sh-menu-item>
            <sh-divider></sh-divider>
          </ng-container>
        </sh-dropdown>
      </div>
      <div class="content-row">
        <sh-text
          size="header-3"
          [innerHTML]="
            'workflowguides.workflows.workflowVersions.arSupport' | translate
          "
        ></sh-text>
        <sh-checkbox
          [disabled]="workflowGuidesService.isReleaseVersionActive()"
          [active]="arSupportInitial"
          [label]="'' // Dont remove otherwise a default label will be shown!"
          (click)="arSupport = !arSupport"
        ></sh-checkbox>
      </div>
    </div>
    <backend-overviews
      [style.display]="section === 'overviews' ? 'block' : 'none'"
    ></backend-overviews>
    <div
      *ngIf="isEditMode"
      [style.display]="section === 'phases' ? 'block' : 'none'"
    >
      <sh-dropdown
        style="width: 300px; margin-left: 5px; margin-bottom: 6px"
        label="Types of phases"
        value="{{ dropdown }}"
        innerclass="child-tab audit-tab"
      >
        <sh-menu-item
          label="Assigned Phases"
          value="Assigned Phases"
          (click)="
            assignedPhases = true;
            unassignedPhase = false;
            AgshowHeaderSave = phases.length > 0 ? true : false
          "
        ></sh-menu-item>
        <sh-menu-item
          label="Unassigned Phases"
          value="Unassigned Phases"
          (click)="
            unassignedPhase = true;
            assignedPhases = false;
            AgshowHeaderSave = true
          "
        ></sh-menu-item>
      </sh-dropdown>
      <sh-table cdkDropList (cdkDropListDropped)="drop($event)">
        <ng-container *ngIf="AgshowHeaderSave">
          <sh-table-row>
            <sh-table-head
              unsortable
              columns="2"
              [innerHTML]=""
            ></sh-table-head>
            <sh-table-head
              unsortable
              columns="4"
              [innerHTML]="
                'workflowguides.phases.phaseWrappers.phaseVersions.key'
                  | translate
              "
            ></sh-table-head>
            <sh-table-head
              unsortable
              columns="6"
              [innerHTML]="
                'workflowguides.phases.phaseWrappers.phaseVersions.name'
                  | translate
              "
            ></sh-table-head>
            <sh-table-head
              unsortable
              columns="8"
              [innerHTML]="
                'workflowguides.phases.phaseWrappers.phaseVersions.description'
                  | translate
              "
            ></sh-table-head>
            <sh-table-head
              unsortable
              columns="4"
              [innerHTML]="
                'workflowguides.phases.phaseWrappers.phaseVersions.version'
                  | translate
              "
            ></sh-table-head>
            <sh-table-head
              unsortable
              columns="6"
              [innerHTML]="
                'workflowguides.phases.phaseWrappers.phaseVersions.versionDescription'
                  | translate
              "
            ></sh-table-head>
          </sh-table-row>
        </ng-container>
        <ng-container *ngIf="assignedPhases">
          <sh-table-row
            *ngFor="let phase of phases"
            cdkDrag
            cdkDragLockAxis="y"
          >
            <sh-table-cell columns="2">
              <sh-checkbox
                [disabled]="workflowGuidesService.isReleaseVersionActive()"
                [active]="hasPhase(phase)"
                [label]="''"
                (checked)="phase.referenced = !phase.referenced"
              ></sh-checkbox>
            </sh-table-cell>
            <sh-table-cell
              columns="4"
              [routerLink]="[
                'phases',
                getPhaseWrapperId(phase.key),
                'versions',
                phase.id
              ]"
              cdkDragHandle
            >
              <p class="flow-text">{{ phase.key }}</p>
            </sh-table-cell>
            <sh-table-cell
              columns="6"
              [routerLink]="[
                'phases',
                getPhaseWrapperId(phase.key),
                'versions',
                phase.id
              ]"
              cdkDragHandle
            >
              <p class="flow-text">
                {{ phase.name | getLocaleText : globalLanguage?.key : true }}
              </p>
            </sh-table-cell>
            <sh-table-cell
              columns="8"
              [routerLink]="[
                'phases',
                getPhaseWrapperId(phase.key),
                'versions',
                phase.id
              ]"
              cdkDragHandle
            >
              <p class="flow-text">
                {{
                  phase.description | getLocaleText : globalLanguage?.key : true
                }}
              </p>
            </sh-table-cell>
            <sh-table-cell
              columns="4"
              [routerLink]="[
                'phases',
                getPhaseWrapperId(phase.key),
                'versions',
                phase.id
              ]"
              cdkDragHandle
            >
              <p class="flow-text">{{ phase.version }}</p>
            </sh-table-cell>
            <sh-table-cell
              columns="6"
              [routerLink]="[
                'phases',
                getPhaseWrapperId(phase.key),
                'versions',
                phase.id
              ]"
              cdkDragHandle
            >
              <p class="flow-text">{{ phase.versionDescription }}</p>
            </sh-table-cell>
            <div class="placeholder" *cdkDragPlaceholder></div>
          </sh-table-row>
        </ng-container>
        <ng-container *ngIf="unassignedPhase">
          <sh-table-row *ngFor="let phase of allPhaseVersions">
            <sh-table-cell columns="2">
              <sh-checkbox
                [disabled]="workflowGuidesService.isReleaseVersionActive()"
                [active]="hasPhase(phase)"
                [label]="''"
                (checked)="phase.referenced = true; phases.push(phase)"
              ></sh-checkbox>
            </sh-table-cell>
            <sh-table-cell columns="4">
              <p class="flow-text">{{ phase.key }}</p>
            </sh-table-cell>
            <sh-table-cell columns="6">
              <p class="flow-text">
                {{ phase.name | getLocaleText : globalLanguage?.key : true }}
              </p>
            </sh-table-cell>
            <sh-table-cell columns="8">
              <p class="flow-text">
                {{
                  phase.description | getLocaleText : globalLanguage?.key : true
                }}
              </p>
            </sh-table-cell>
            <sh-table-cell columns="4">
              <p class="flow-text">{{ phase.version }}</p>
            </sh-table-cell>
            <sh-table-cell columns="6">
              <p class="flow-text">{{ phase.versionDescription }}</p>
            </sh-table-cell>
          </sh-table-row>
        </ng-container>
      </sh-table>
    </div>
  </div>

  <div *ngIf="!workflowGuidesService.isReleaseVersionActive() && AgshowHeaderSave" class="buttons">
    <sh-button
      [disabled]="!deviceVersion"
      size="m"
      (click)="section == 'phases' && !workflowGuidesService.isReleaseVersionActive() ? showReleaseWorkflowModal = true : save() "
      [label]="section == 'phases' && !workflowGuidesService.isReleaseVersionActive() ? 'Release' : 'general.save' | translate"
    ></sh-button>
    <sh-button
      color="secondary"
      (click)="cancel()"
      size="m"
      [label]="'general.cancel' | translate"
    ></sh-button>
  </div>

  <sh-modal
    sticky
    id="sticky-modal"
    type="warning"
    label="Release Draft Version"
    modal-height="250px"
    modal-width="500px"
    [visible]="showReleaseWorkflowModal"
  >
    You have selected draft version to release. This shall release the draft
    workflow to production. Any existing released workflow will be overwritten.
    <br />
    <br />
    Do you want to continue?
    <sh-button
      color="secondary"
      slot="footer"
      label="No"
      (click)="showReleaseWorkflowModal = false"
    ></sh-button>
    <sh-button slot="footer" label="Yes" (click)="releaseWorkflow()"></sh-button>
  </sh-modal>
</div>
