<sh-card>
  <div class="search-header">
    <sh-search
            size="body-2"
            class="search-box"
            [label]="'general.search' | translate"
            [value]="searchString"
            (value-changed)="onSearchString($event.target.value)"
    ></sh-search>
    <sh-button
            size="max-content"
            [label]="'general.add' | translate"
            [routerLink]="'add'"
    ></sh-button>
    </div>
    <div style="display: flex;">
      <sh-text size="header-1">
          {{ 'header.tabs.workflows' | translate }}
          {{ getallWorkflowsCount() }}
      </sh-text>
      <div style="display: flex;">
          <sh-text size="header-1"> {{ 'general.sort' | translate }}: </sh-text>
          <sh-icon
            *ngIf="isAscending"
            button
            size="s"
            icon="sort-ascending"
            class="sort-icon"
            (click)="toggleSort()"
          >
          </sh-icon>
          <sh-icon
            *ngIf="!isAscending"
            button
            size="s"
            icon="sort-descending"
            class="sort-icon"
            (click)="toggleSort()"
          >
          </sh-icon>
      </div>
    </div>
  <sh-table (scroll-end)="onScrollEnd()">
    <sh-table-row>

      <!-- Thumbnail      -->
      <sh-table-cell unsortable columns="3">
        <sh-text
          size="title-1"
          [innerText]="'workflowguides.workflows.thumbnailCol' | translate"
        ></sh-text>
      </sh-table-cell>

      <!-- Workflow name      -->
      <sh-table-cell unsortable columns="3">
        <sh-text
          size="title-1"
          [innerText]="'workflowguides.workflows.titleCol' | translate"
        ></sh-text>
      </sh-table-cell>

      <!-- Workflow Description      -->
      <sh-table-cell unsortable columns="12">
        <sh-text
          size="title-1"
          [innerText]="'workflowguides.workflows.descriptionCol' | translate"
        ></sh-text>
      </sh-table-cell>


      <sh-table-cell unsortable columns="3">
        <sh-text
          size="title-1"
          [innerText]="'workflowguides.workflows.businessLine' | translate"
        ></sh-text>
      </sh-table-cell>


      <sh-table-cell unsortable columns="2">
        <sh-text
          size="title-1"
          [innerText]="
            'workflowguides.workflows.workflowVersions.status' | translate
          "
        ></sh-text>
      </sh-table-cell>
      <sh-table-head unsortable icon columns="1"></sh-table-head>
      <sh-table-head unsortable icon columns="1"></sh-table-head>
    </sh-table-row>
    <ng-container *ngIf="allWorkflows">
    <sh-table-row
        *ngFor="let workflow of displayedWorkflows"
        (contextmenu)="
          popoverService.openPopover(
            popovers,
            'wf-menu-' + workflow?.id,
            $event
          )
        "
        (click)="popoverService.goToRoute(popovers, [workflow.id, 'versions', workflow.workflowDraftVersion ? workflow.workflowDraftVersion?.id : workflow.workflowReleasedVersion?.id]); $event.stopPropagation();">

        <sh-table-cell columns="3">
          <img
            *ngIf="workflow.thumbnail"
            [src]="workflow.thumbnail | s3Image | async"
            class="thumbnailImg flow-text"
          />
        </sh-table-cell>

        <sh-table-cell columns="3">
          <p
            class="flow-text"
            [innerText]="
              workflow.title | getLocaleText : globalLanguage?.key : true
            "
          ></p>
        </sh-table-cell>


        <sh-table-cell columns="12">
          <p
            class="flow-text"
            [innerText]="
              workflow.description | getLocaleText : globalLanguage?.key : true
            "
          ></p>
        </sh-table-cell>


        <sh-table-cell columns="3">
          <p class="flow-text" [innerText]="BusinessLineUtility.getBusinessLineName(workflow.businessLine)"></p>
        </sh-table-cell>
        
        <sh-table-cell columns="2">
          <sh-badge
             *ngIf="workflow.workflowReleasedVersion"
            label="Release"
          ></sh-badge>
          <sh-badge
            class="draft"
            *ngIf="workflow.workflowDraftVersion"
            label="Draft"
          ></sh-badge>
        </sh-table-cell>
        <sh-table-cell icon columns="1">
          <sh-icon
            button
            size="s"
            icon="delete"
            (click)="promptDeleteWorkflow(workflow); $event.stopPropagation()"
          >
          </sh-icon>
        </sh-table-cell>
        <sh-table-cell icon columns="1">
          <sh-icon
            button
            size="s"
            icon="edit"
            [label]="'general.edit' | translate"
            [routerLink]="[workflow.id, 'edit']"
          >
          </sh-icon>
        </sh-table-cell>
      </sh-table-row>
    </ng-container>

    <sh-table-row *ngIf="workflowService.workflows$ != null && displayedWorkflows.length == 0">
      <sh-table-cell columns="5"></sh-table-cell>
      <sh-table-cell
        columns="19"
        [innerText]="'workflowguides.workflows.empty' | translate"
      ></sh-table-cell>
    </sh-table-row>
  </sh-table>
  
  <sh-modal
    sticky
    id="sticky-modal"
    type="warning"
    label="Modal Label"
    modal-height="300px"
    modal-width="500px"
    [visible]="showDeleteWorkflowModal"
  >
    You are going to delete 
    {{
      workflowTobeDeleted?.title
        | getLocaleText : globalLanguage?.key : true
    }}. Deleting will
    permanently remove the content from CMS portal and will not be available for
    viewing the ExpertGuidance app.
    <br />
    <br />
    Do you want to delete ?
    <br />
    <br />
    <div style="padding-left: 10px">
      <sh-checkbox
        label="Release"
        *ngIf="workflowTobeDeleted?.workflowReleasedVersion"
        (checked)="deleteReleaseVersionReq = !deleteReleaseVersionReq"
        [active]="deleteReleaseVersionReq"
      ></sh-checkbox>
      <sh-checkbox
        label="Draft"
        *ngIf="workflowTobeDeleted?.workflowDraftVersion"
        (checked)="deleteDraftVersionReq = !deleteDraftVersionReq"
        [active]="deleteDraftVersionReq"
      ></sh-checkbox>
    </div>

    <sh-button
      color="secondary"
      slot="footer"
      label="No"
      (click)="resetDeleteWorkflowModalValue()"
    ></sh-button>
    <sh-button
      [disabled]="
        !(
          deleteDraftVersionReq ||
          deleteReleaseVersionReq ||
          deleteWorkflowWithoutVersion
        )
      "
      slot="footer"
      label="Yes"
      (click)="deleteWorkflow()"
    ></sh-button>
</sh-modal>
</sh-card>