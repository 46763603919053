<div>
  <sh-table cdkDropList (cdkDropListDropped)="drop($event)">
    <sh-table-row>
      <sh-table-head
        unsortable
        columns="4"
        [innerHTML]="
          'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.name'
            | translate
        "
      >
      </sh-table-head>
      <sh-table-head
        unsortable
        columns="3"
        [innerHTML]="
          'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.interaction'
            | translate
        "
      >
      </sh-table-head>
      <sh-table-head
        unsortable
        columns="4"
        [innerHTML]="
          'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.layout'
            | translate
        "
      >
      </sh-table-head>
      <sh-table-head
        unsortable
        columns="8"
        [innerHTML]="
          'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.instructions'
            | translate
        "
      >
      </sh-table-head>
      <sh-table-head
        unsortable
        columns="5"
        [innerHTML]="
          'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.Question'
            | translate
        "
      >
      </sh-table-head>
      <sh-table-head
        unsortable
        columns="2"
        [innerHTML]="
          'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.countAssets'
            | translate
        "
      >
      </sh-table-head>
      <sh-table-head unsortable icon></sh-table-head>
    </sh-table-row>

    <ng-container *ngIf="steps">
      <sh-table-row *ngFor="let step of steps" cdkDrag cdkDragLockAxis="y">
        <sh-table-cell
          columns="4"
          [routerLink]="['steps', step.id]"
          cdkDragHandle
        >
          <p class="flow-text" [innerText]="step.key"></p>
        </sh-table-cell>
        <sh-table-cell
          columns="3"
          [routerLink]="['steps', step.id]"
          cdkDragHandle
        >
          <p class="flow-text" [innerText]="step.interaction"></p>
        </sh-table-cell>
        <sh-table-cell
          columns="4"
          [routerLink]="['steps', step.id]"
          cdkDragHandle
        >
          <p class="flow-text" [innerText]="step.layout"></p>
        </sh-table-cell>
        <sh-table-cell
          columns="8"
          [routerLink]="['steps', step.id]"
          cdkDragHandle
        >
          <p
            class="flow-text"
            [innerText]="
              step.instructions | getLocaleText : globalLanguage?.key : true
            "
          ></p>
        </sh-table-cell>
        <sh-table-cell
          columns="5"
          [routerLink]="['steps', step.id]"
          cdkDragHandle
        >
          <p
            class="flow-text"
            [innerText]="
              step.question | getLocaleText : globalLanguage?.key : true
            "
          ></p>
        </sh-table-cell>
        <sh-table-cell
          columns="2"
          [routerLink]="['steps', step.id]"
          cdkDragHandle
        >
          <p class="flow-text" [innerText]="step.assets?.length"></p>
        </sh-table-cell>
        <sh-table-cell
          icon
          (click)="deleteStep(step.id, step.key)"
          cdkDragHandle
        >
          <sh-icon button *ngIf="!linksService.isReleaseVersionActive()" icon="delete"></sh-icon>
        </sh-table-cell>
        <div class="placeholder" *cdkDragPlaceholder></div>
      </sh-table-row>
    </ng-container>

    <sh-table-row *ngIf="steps?.length === 0">
      <sh-table-cell columns="5"></sh-table-cell>
      <sh-table-cell
        columns="19"
        [innerText]="
          'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.empty'
            | translate
        "
      >
      </sh-table-cell>
    </sh-table-row>
  </sh-table>

  <div class="buttons"  *ngIf="!linksService.isReleaseVersionActive()">
    <sh-button
      size="m"
      [label]="'general.add' | translate"
      [routerLink]="['steps', 'add']"
    ></sh-button>
  </div>
</div>
