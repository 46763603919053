import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { WorkflowService } from '../workflow.service';
import { Router } from '@angular/router';
import { LanguageService } from '../../../services/language.service';
import { DeviceAndWorkflowStatus } from '@backend/interfaces';
import { ImgConvertorComponent } from '@backend/webapp/img-convertor/img-convertor.component';
import { WorkflowGuidesService } from '../../workflowguides.service';
import { LocalizationService } from '@backend/webapp/shared/localization/localization.service';
import { Constants } from '@backend/interfaces';
import { GetLocaleTextPipe } from '@backend/webapp/get-locale-text.pipe';
import { Observable, Subscription } from 'rxjs';
import { BusinessLineUtility } from '@backend/webapp/shared/businessLine/business-line.utility';
import { Workflow } from '@backend/api/WorkflowGuides/Workflow/workflow.model';
import ShortUniqueId from 'short-unique-id';
import { SpinnerService } from '@backend/webapp/spinner/spinner.service';
const uidGen = new ShortUniqueId();

@Component({
  selector: 'backend-edit-workflow',
  templateUrl: './edit-workflow.component.html',
  styleUrls: ['./edit-workflow.component.scss'],
})
export class EditWorkflowComponent implements OnInit, OnDestroy {
  @ViewChild('previewImage')
  public previewImage: ElementRef<HTMLImageElement>;

  @ViewChild('inputFile')
  public inputFile: ElementRef<HTMLInputElement>;

  public isEditMode = false;
  public copiedWorkflow: Workflow;
  public id: number;
  public key: string;
  public title: string;
  public arInitial: boolean = false;
  public ar: boolean = false;
  public description: string;
  public name: string;
  public thumbnail: string;
  public thumbnailBasename: string;
  public thumbnailChanged = false;
  public isWorkflowNameValid = true;
  copyExistingWorkflow = false;
  public status = '';
  public statusValues = this.workflowService.getStatusEnumKeys();
  private imgConvertor: ImgConvertorComponent;
  public globalLanguage = Constants.DEFAULT_GLOBAL_LANGUAGE;
  titleTrans: any[];
  descriptionTrans: any[];
  public businessLines: string[];
  public businessLine: string = '';
  languageSubscription: Subscription;
  workflowList: any[];
  editWorkflow: Workflow;
  public addressableGroupName: string = '';
  public addressableGroupNames: string[] = ['none'];
  public workflows: Observable<any[]>;
  workflowIdToBeCopied: number;
  deviceVersionToBeCopyFrom: number;
  file: File;

  public constructor(
    private readonly router: Router,
    private readonly renderer: Renderer2,
    private readonly workflowService: WorkflowService,
    public readonly languageService: LanguageService,
    public readonly linksService: WorkflowGuidesService,
    public readonly localization: LocalizationService,
    private readonly spinner : SpinnerService
  ) {
    this.imgConvertor = new ImgConvertorComponent();
    this.businessLines = BusinessLineUtility.getBusinessLinesNames();
  }

  public async ngOnInit(): Promise<void> {
    this.spinner.showSpinner();
    this.resetFields();
    this.addressableGroupNames = this.addressableGroupNames.concat(
      await this.workflowService.getAddressableGroupNames()
    );
    this.languageSubscription = this.localization.globalLanguage$.subscribe(
      async (data) => {
        if (this.router.isActive) {
          this.globalLanguage = data;
        }
      }
    );
    await this.updateFields();
    await this.workflowService.loadWorkflows();
    this.workflowService.workflowswithVersions.subscribe({
      next: (value) => {
        this.workflowList = value;
      },
    });
    this.spinner.hideSpinner();
  }

  ngOnDestroy() {
    if (this.languageSubscription) {
      this.languageSubscription.unsubscribe();
    }
  }

  selectFile() {
    this.inputFile.nativeElement.click();
  }

  private async updateFields(): Promise<void> {
    let workflow = await this.workflowService.getCurrentWorkFlow();
    this.editWorkflow = workflow;
    if (workflow) {
      this.updateWorkflowField(workflow);
      this.isEditMode = true;
    } else {
      this.resetFields();
      this.isEditMode = false;
    }
  }

  private updateWorkflowField(workflow: Workflow): void {
    this.id = workflow.id;
    this.key = workflow.key;
    this.title = this.languageService.getTranslationByKey(
      workflow.title,
      this.globalLanguage.key
    );
    this.titleTrans = new GetLocaleTextPipe(this.localization).transform(
      workflow.title,
      this.globalLanguage.key
    );
    this.description = this.languageService.getTranslationByKey(
      workflow.description,
      this.globalLanguage.key
    );
    this.descriptionTrans = new GetLocaleTextPipe(this.localization).transform(
      workflow.description,
      this.globalLanguage.key
    );
    this.businessLine = BusinessLineUtility.getBusinessLineName(
      workflow.businessLine
    );
    this.workflowIdToBeCopied = workflow.id;
    if (workflow.workflowReleasedVersion != null)
    {
        this.addressableGroupName = workflow.workflowReleasedVersion.simulationProduct;
    }
    else if (workflow.workflowDraftVersion != null)
    {
        this.addressableGroupName = workflow.workflowDraftVersion.simulationProduct;
    }
    else
    {
        this.addressableGroupName = "none";
    }

    this.thumbnail = workflow.thumbnail;
    if (this.thumbnail) {
      this.thumbnailBasename = this.thumbnail.replace(/.*[\/\\]/, '');
    } else {
      this.thumbnailBasename = '';
    }
    this.ar = this.arInitial = workflow.ar ?? false;
  }

  private resetFields(): void {
    this.key = '';
    this.title = '';
    this.thumbnail = '';
    this.thumbnailBasename = '';
    this.thumbnailChanged = false;
    this.status = null;
    this.ar = false;
  }

  public async save() {
    this.spinner.showSpinner();
    this.status = 'Draft';
    let result: boolean;
    let file;
    if (this.thumbnailChanged) {
      file = this.imgConvertor.getImageFile();
    } else {      
      file = null;
    }
    if (this.copyExistingWorkflow) {
      result = await this.workflowService.createWorkflow(
        uidGen.randomUUID(8),
        this.title,
        this.description,
        this.ar,
        file,
        DeviceAndWorkflowStatus[this.status.toUpperCase()],
        BusinessLineUtility.getBusinessLine(this.businessLine),
        this.thumbnailBasename,
        this.workflowIdToBeCopied
      );
    } else {
      if (!this.isEditMode) {
        result = await this.workflowService.createWorkflow(
          uidGen.randomUUID(8),
          this.title,
          this.description,
          this.ar,
          file,
          DeviceAndWorkflowStatus[this.status.toUpperCase()],
          BusinessLineUtility.getBusinessLine(this.businessLine),
          this.thumbnailBasename,
          -1
        );
      } else {
        result = await this.workflowService.editWorkflow(
          this.id,
          this.title,
          this.description,
          this.ar,
          file,
          DeviceAndWorkflowStatus[this.status.toUpperCase()],
          BusinessLineUtility.getBusinessLine(this.businessLine)
        );
      }
    }
    if (result) {
      this.gotoWorkflows();
      this.linksService.loadWfLinks();
    }
    this.spinner.hideSpinner();
  }

  public async cancel() {
    this.gotoWorkflows();
  }

  public gotoWorkflows(): void {
    this.router.navigate(['workflowguides/workflows']);
  }

  public async checkForValidName(workflowName): Promise<void> {
    this.title = workflowName.trim();
    if (this.isEditMode && this.editWorkflow) {
      const editWorkflowTitle = new GetLocaleTextPipe(
        this.localization
      ).transform(this.editWorkflow.title, this.globalLanguage.key);
      if (
        this.title?.toLowerCase() === editWorkflowTitle.trim().toLowerCase()
      ) {
        this.isWorkflowNameValid = true;
        return;
      }
    }

    if (!this.workflowList) {
      return;
    }
    for (let workflow of this.workflowList) {
      const workflowTitle = new GetLocaleTextPipe(this.localization).transform(
        workflow.title,
        this.globalLanguage.key
      );

      if (workflowTitle.trim()?.toLowerCase() === this.title?.toLowerCase()) {
        this.isWorkflowNameValid = false;
        break;
      } else {
        this.isWorkflowNameValid = true;
      }
    }
  }
  public fileChange(file: File): void {
    if (file) {
      this.thumbnail = file.name;
      this.thumbnailBasename = file.name.replace(/.*[\/\\]/, '');
      this.thumbnailChanged = true;
      const reader = new FileReader();
      reader.onloadend = (e) => {
        let image = new Image();
        this.renderer.setAttribute(
          this.previewImage.nativeElement,
          'src',
          e.target.result.toString()
        );
        image.src = e.target.result.toString();
        image.onload = () => {
          this.imgConvertor.drawImageOnCanvas(image, this.thumbnailBasename);
        };
      };
      reader.readAsDataURL(file);
    } else {
      this.thumbnail = '';
      this.thumbnailBasename = '';
    }
  }

  changeAr() {
    this.ar = !this.ar;
  }

  public copyDevice(workflow: Workflow): void {
    this.copiedWorkflow = workflow;
    this.updateWorkflowField(workflow);
    this.status = this.statusValues[workflow.status];
  }
}
