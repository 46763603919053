import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../auth/auth.guard';
import { EditPhaseWrapperComponent } from './phases/edit-phase-wrapper/edit-phase-wrapper.component';
import { EditKeyActivityComponent } from './phases/phase-versions/key-activities/edit-key-activity/edit-key-activity.component';

import { PhasesComponent } from './phases/phases.component';
import { EditPhaseVersionComponent } from './phases/phase-versions/edit-phase-version/edit-phase-version.component';
import { PhaseVersionsComponent } from './phases/phase-versions/phase-versions.component';
import { WorkflowGuidesComponent } from './workflowguides.component';
import { WorkflowsComponent } from './workflows/workflows.component';
import { EditStepComponent } from './phases/phase-versions/key-activities/steps/edit-step/edit-step.component';
import { EditWorkflowComponent } from './workflows/edit-workflow/edit-workflow.component';
import { WorkflowVersionsComponent } from './workflows/workflow-versions/workflow-versions.component';
import { EditWorkflowVersionComponent } from './workflows/workflow-versions/edit-workflow-version/edit-workflow-version.component';
import { EditOverviewComponent } from '@backend/webapp/workflowguides/workflows/workflow-versions/overviews/edit-overview/edit-overview.component';
import { EditBlockComponent } from '@backend/webapp/workflowguides/workflows/workflow-versions/overviews/blocks/edit-block/edit-block.component';
import { EditAuthorComponent } from '@backend/webapp/workflowguides/workflows/workflow-versions/overviews/blocks/edit-author/edit-author.component';
import { EditAssetComponent } from '@backend/webapp/workflowguides/phases/phase-versions/key-activities/steps/assets/edit-asset/edit-asset.component';
import { EditHotspotComponent } from '@backend/webapp/workflowguides/phases/phase-versions/key-activities/steps/assets/hotspots/edit-hotspot/edit-hotspot.component';

const workflowGuidesRoutes: Routes = [
  {
    path: '',
    component: WorkflowGuidesComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: 'workflows',
        component: WorkflowsComponent,
      },
      {
        path: 'workflows/add',
        component: EditWorkflowComponent,
      },
      {
        path: 'workflows/:workflowId/edit',
        component: EditWorkflowComponent,
      },
      {
        path: 'workflows/:workflowId/versions',
        component: WorkflowVersionsComponent,
      },
      {
        path: 'workflows/:workflowId/versions/add',
        component: EditWorkflowVersionComponent,
      },
      {
        path: 'workflows/:workflowId/versions/:workflowVersionId',
        component: EditWorkflowVersionComponent,
      },
      {
        path: 'workflows/:workflowId/versions/:workflowVersionId/overviews',
        component: EditOverviewComponent,
      },
      {
        path: 'workflows/:workflowId/versions/:workflowVersionId/overviews/:overviewId',
        component: EditOverviewComponent,
      },
      {
        path: 'workflows/:workflowId/versions/:workflowVersionId/overviews/:overviewId/blocks/:blockType',
        component: EditBlockComponent,
      },
      {
        path: 'workflows/:workflowId/versions/:workflowVersionId/overviews/:overviewId/blocks/:blockId/:blockType',
        component: EditBlockComponent,
      },
      {
        path: 'workflows/:workflowId/versions/:workflowVersionId/overviews/:overviewId/blocks/:blockId/author/edit',
        component: EditAuthorComponent,
      },
      {
        path: 'workflows/:workflowId/versions/:workflowVersionId/overviews/:overviewId/blocks/:blockId/author/:authorId',
        component: EditAuthorComponent,
      },
      {
        path: 'workflows/:workflowId/versions/:workflowVersionId/phases/:phaseWrapperId/versions',
        component: EditWorkflowVersionComponent,
      },
      {
        path: 'workflows/:workflowId/versions/:workflowVersionId/phases/:phaseWrapperId/versions/:phaseVersionId',
        component: EditPhaseVersionComponent,
      },
      {
        path: 'workflows/:workflowId/versions/:workflowVersionId/phases/:phaseWrapperId/versions/:phaseVersionId/keyactivities/add',
        component: EditKeyActivityComponent,
      },
      {
        path: 'workflows/:workflowId/versions/:workflowVersionId/phases/:phaseWrapperId/versions/:phaseVersionId/keyactivities/:keyActivityId',
        component: EditKeyActivityComponent,
      },
      {
        path: 'workflows/:workflowId/versions/:workflowVersionId/phases/:phaseWrapperId/versions/:phaseVersionId/keyactivities/:keyActivityId/steps/add',
        component: EditStepComponent,
      },
      {
        path: 'workflows/:workflowId/versions/:workflowVersionId/phases/:phaseWrapperId/versions/:phaseVersionId/keyactivities/:keyActivityId/steps/:stepId',
        component: EditStepComponent,
      },
      {
        path: 'workflows/:workflowId/versions/:workflowVersionId/phases/:phaseWrapperId/versions/:phaseVersionId/keyactivities/:keyActivityId/steps/:stepId/assets/:assetType',
        component: EditAssetComponent,
      },
      {
        path: 'workflows/:workflowId/versions/:workflowVersionId/phases/:phaseWrapperId/versions/:phaseVersionId/keyactivities/:keyActivityId/steps/:stepId/assets/:assetType/:assetId',
        component: EditAssetComponent,
      },
      {
        path: 'workflows/:workflowId/versions/:workflowVersionId/phases/:phaseWrapperId/versions/:phaseVersionId/keyactivities/:keyActivityId/steps/:stepId/assets/:assetType/:assetId/hotspots',
        component: EditHotspotComponent,
      },
      {
        path: 'workflows/:workflowId/versions/:workflowVersionId/phases/:phaseWrapperId/versions/:phaseVersionId/keyactivities/:keyActivityId/steps/:stepId/assets/:assetType/:assetId/hotspots/add',
        component: EditHotspotComponent,
      },
      {
        path: 'workflows/:workflowId/versions/:workflowVersionId/phases/:phaseWrapperId/versions/:phaseVersionId/keyactivities/:keyActivityId/steps/:stepId/assets/:assetType/:assetId/hotspots/:hotspotId',
        component: EditHotspotComponent,
      },
      {
        path: 'phases',
        component: PhasesComponent,
      },
      {
        path: 'phases/add',
        component: EditPhaseWrapperComponent,
      },
      {
        path: 'phases/:phaseWrapperId/edit',
        component: EditPhaseWrapperComponent,
      },
      {
        path: 'phases/:phaseWrapperId/versions',
        component: PhaseVersionsComponent,
      },
      {
        path: 'phases/:phaseWrapperId/versions/add',
        component: EditPhaseVersionComponent,
      },
      {
        path: 'phases/:phaseWrapperId/versions/:phaseVersionId',
        component: EditPhaseVersionComponent,
      },
      {
        path: 'phases/:phaseWrapperId/versions/:phaseVersionId/keyactivities/add',
        component: EditKeyActivityComponent,
      },
      {
        path: 'phases/:phaseWrapperId/versions/:phaseVersionId/keyactivities/:keyActivityId',
        component: EditKeyActivityComponent,
      },
      {
        path: 'phases/:phaseWrapperId/versions/:phaseVersionId/keyactivities/:keyActivityId/steps/add',
        component: EditStepComponent,
      },
      {
        path: 'phases/:phaseWrapperId/versions/:phaseVersionId/keyactivities/:keyActivityId/steps/:stepId',
        component: EditStepComponent,
      },
      {
        path: 'phases/:phaseWrapperId/versions/:phaseVersionId/keyactivities/:keyActivityId/steps/:stepId/assets/:assetType',
        component: EditAssetComponent,
      },
      {
        path: 'phases/:phaseWrapperId/versions/:phaseVersionId/keyactivities/:keyActivityId/steps/:stepId/assets/:assetType/:assetId',
        component: EditAssetComponent,
      },
      {
        path: 'phases/:phaseWrapperId/versions/:phaseVersionId/keyactivities/:keyActivityId/steps/:stepId/assets/:assetType/:assetId/hotspots',
        component: EditHotspotComponent,
      },
      {
        path: 'phases/:phaseWrapperId/versions/:phaseVersionId/keyactivities/:keyActivityId/steps/:stepId/assets/:assetType/:assetId/hotspots/add',
        component: EditHotspotComponent,
      },
      {
        path: 'phases/:phaseWrapperId/versions/:phaseVersionId/keyactivities/:keyActivityId/steps/:stepId/assets/:assetType/:assetId/hotspots/:hotspotId',
        component: EditHotspotComponent,
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'workflows',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(workflowGuidesRoutes)],
  exports: [],
})
export class WorkflowGuidesRoutingModule {}
