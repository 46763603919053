import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ParamsService } from '../../../../../services/params.service';
import { LanguageService } from '@backend/webapp/services/language.service';
import { ConfirmationService } from '@backend/webapp/shared/confirmation/confirmation.service';
import { SpinnerService } from '@backend/webapp/spinner/spinner.service';
import { NotificationService } from '@backend/webapp/shared/notification/notification.service';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { WorkflowGuidesService } from '@backend/webapp/workflowguides/workflowguides.service';
import { lastValueFrom } from 'rxjs';
import { WorkflowSimulationLockState } from '@backend/api/WorkflowGuides/PhaseWrapper/Phase/keyActivities/step/workflowSimulationLockState.enum';
import { Step } from '@backend/api/WorkflowGuides/PhaseWrapper/Phase/keyActivities/step/step.model';

@Injectable({
  providedIn: 'root',
})
export class StepService {
  private get apiRoute(): string {
    return `/api/workflowguides/phases/${this.paramsService.phaseWrapperId}/versions/${this.paramsService.phaseVersionId}/keyactivities/${this.paramsService.keyActivityId}/steps/`;
  }

  public constructor(
    private readonly http: HttpClient,
    private readonly paramsService: ParamsService,
    private readonly languageService: LanguageService,
    private confirmationService: ConfirmationService,
    private spinner: SpinnerService,
    private notificationService: NotificationService,
    private readonly linksService: WorkflowGuidesService
  ) {}

  public async getSteps(): Promise<any[]> {
    this.paramsService.isLoading = true;
    const result = await lastValueFrom(this.http.get<any[]>(this.apiRoute));
    this.paramsService.isLoading = false;
    return result;
  }

  public async createStep(
    key: string,
    interaction: string,
    layout: string,
    isAssessmentAvailable: boolean,
    defaultAxisLockState: WorkflowSimulationLockState,
    defaultControlLockState: WorkflowSimulationLockState,
    systemPosition: string
  ): Promise<any> {
    return await lastValueFrom(
      this.http.post(this.apiRoute, {
        key,
        interaction,
        layout,
        isAssessmentAvailable: isAssessmentAvailable,
        defaultAxisLockState,
        defaultControlLockState,
        systemPosition,
      })
    );
  }

  public async editStep(
    key: string,
    id: number,
    interaction: string,
    layout: string,
    isAssessmentAvailable: boolean,
    defaultAxisLockState: WorkflowSimulationLockState,
    defaultControlLockState: WorkflowSimulationLockState,
    systemPosition: string
  ): Promise<any> {
    return await lastValueFrom(
      this.http.put(this.apiRoute + id, {
        key,
        interaction,
        layout,
        isAssessmentAvailable: isAssessmentAvailable,
        defaultAxisLockState,
        defaultControlLockState,
        systemPosition,
      })
    );
  }

  public async editInstructions(
    id: number,
    instructions: string
  ): Promise<any> {
    return await lastValueFrom(
      this.http.put(this.apiRoute + id + '/instructions', {
        instructions,
        langKey: this.languageService.guiLanguageCode,
      })
    );
  }

  public async editQuestion(id: number, question: string): Promise<any> {
    return await lastValueFrom(
      this.http.put(this.apiRoute + id + '/question', {
        question,
        langKey: this.languageService.guiLanguageCode,
      })
    );
  }

  public async deleteStep(id: number, step) {
    if (
      await this.confirmationService.confirm(
        'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.delete',
        'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.deleteConfirmation',
        { step }
      )
    ) {
      this.paramsService.isLoading = true;
      try {
        await lastValueFrom(this.http.delete(this.apiRoute + id));
      } catch (e) {
        console.log(e);
      } finally {
        this.paramsService.isLoading = false;
      }
    }
  }

  public async getStep(id: number = this.paramsService.stepId): Promise<Step> {
    this.paramsService.isLoading = true;
    const result = await lastValueFrom(this.http.get<Step>(this.apiRoute + id));
    this.paramsService.isLoading = false;
    return result;
  }

  public async getSystemPositions(): Promise<any[]>{
    this.paramsService.isLoading = true;
    const allSystemPositions = await lastValueFrom(this.http.get<any[]>(this.apiRoute + 'simulationSystemPositions')); 
    let systemPositions: Array<string> = [];
    allSystemPositions.forEach(function(item) {
      systemPositions.push(item.systemPosition);
    });    
    this.paramsService.isLoading = false;
    return systemPositions;
  }

  public async createDevice(id: number): Promise<void> {
    await lastValueFrom(
      this.http.post(this.apiRoute + id + '/setup/devices', {})
    );
  }

  public async deleteDevice(
    stepId: number,
    deviceId: number,
    device
  ): Promise<void> {
    if (
      await this.confirmationService.confirm(
        'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.setup.device.delete',
        'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.setup.device.deleteConfirmation',
        { device }
      )
    ) {
      this.paramsService.isLoading = true;
      try {
        await lastValueFrom(
          this.http.delete(
            this.apiRoute + stepId + '/setup/devices/' + deviceId
          )
        );
      } catch (e) {
        console.log(e);
      } finally {
        this.paramsService.isLoading = false;
      }
    }
  }

  public async createControl(stepId: number): Promise<void> {
    await lastValueFrom(
      this.http.post(this.apiRoute + stepId + '/setup/controls', {})
    );
  }

  public async deleteControl(
    stepId: number,
    controlId: number,
    control
  ): Promise<void> {
    if (
      await this.confirmationService.confirm(
        'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.setup.control.delete',
        'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.setup.control.deleteConfirmation',
        { control }
      )
    ) {
      this.paramsService.isLoading = true;
      try {
        await lastValueFrom(
          this.http.delete(
            this.apiRoute + stepId + '/setup/controls/' + controlId
          )
        );
      } catch (e) {
        console.log(e);
      } finally {
        this.paramsService.isLoading = false;
      }
    }
  }

  public async editDevice(
    stepId: number,
    deviceId: number,
    key: string,
    lockState: WorkflowSimulationLockState
  ): Promise<void> {
    await lastValueFrom(
      this.http.put(this.apiRoute + stepId + '/setup/devices/' + deviceId, {
        key,
        lockState,
      })
    );
  }

  public async editControl(
    stepId: number,
    controlId: number,
    key: string,
    lockState: WorkflowSimulationLockState
  ): Promise<void> {
    await lastValueFrom(
      this.http.put(this.apiRoute + stepId + '/setup/controls/' + controlId, {
        key,
        lockState,
      })
    );
  }

  public async createAxis(deviceId: number): Promise<void> {
    await lastValueFrom(
      this.http.post(this.apiRoute + 'device/' + deviceId + '/axis/', {})
    );
  }

  public async editAxis(axisId: number, axis: any) {
    await lastValueFrom(this.http.put(this.apiRoute + 'axes/' + axisId, axis));
  }

  public async deleteAxis(axisId: number, axis) {
    if (
      await this.confirmationService.confirm(
        'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.setup.device.axes.delete',
        'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.setup.device.axes.deleteConfirmation',
        { axis }
      )
    ) {
      this.paramsService.isLoading = true;
      try {
        await lastValueFrom(this.http.delete(this.apiRoute + 'axes/' + axisId));
      } catch (e) {
        console.log(e);
      } finally {
        this.paramsService.isLoading = false;
      }
    }
  }

  public async orderSteps(
    steps: any[],
    previousIndex: number,
    currentIndex: number
  ): Promise<any> {
    if (
      await this.confirmationService.confirm(
        'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.rearrangeSteps',
        'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.rearrangeStepsConfirmation'
      )
    ) {
      this.spinner.showSpinner();
      try {
        moveItemInArray(steps, previousIndex, currentIndex);
        await lastValueFrom(
          this.http.put(this.apiRoute + 'order/steps', { steps })
        );
        this.notificationService.confirmation(
          'general.success',
          'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.rearrangeSuccess'
        );
        return true;
      } catch (error) {
        this.notificationService.error(
          'general.error',
          'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.rearrangeError',
          error.error.message
        );
        return false;
      } finally {
        this.spinner.hideSpinner();
      }
    }
  }

  public async createSimulationStepPosition(
    id: number,
    stepPosition: string
  ): Promise<any> {
    await lastValueFrom(
      this.http.post(this.apiRoute + id + '/simulationSystemPosition', { stepPosition })
    );
  } 
}