<div>
  <sh-nav-bar slot="nav">
    <sh-tabs>
      <sh-tab-item
        [label]="
          'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.tabBase'
            | translate
        "
        [active]="section === 'base'"
        (click)="section = 'base'"
      ></sh-tab-item>
      <sh-tab-item
        [label]="
          'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.tabSteps'
            | translate
        "
        [active]="section === 'steps'"
        [disabled]="!id"
        (click)="section = 'steps'"
      ></sh-tab-item>
    </sh-tabs>
  </sh-nav-bar>
  <div class="content" [style.display]="section === 'base' ? 'flex' : 'none'">
    <div class="content-row">
      <sh-text
        size="header-3"
        [innerHTML]="
          'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.key'
            | translate
        "
      ></sh-text>
      <sh-input-text
        mandatory
        [disabled]="isEditMode"
        [value]="key"
        [label]="
          'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.key'
            | translate
        "
        (value-changed)="key = $event.target.value"
      ></sh-input-text>
    </div>
    <div class="content-row">
      <sh-text
        size="header-3"
        [innerHTML]="
          'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.titleCol'
            | translate
        "
      ></sh-text>
      <sh-input-text
        mandatory
        [disabled]="linksService.isReleaseVersionActive()"
        [label]="
          'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.titleCol'
            | translate
        "
        [value]="titleTrans"
        (value-changed)="title = $event.target.value"
      ></sh-input-text>
    </div>
  </div>

  <div class="content" [style.display]="section === 'steps' ? 'flex' : 'none'">
    <backend-steps></backend-steps>
  </div>

  <div class="buttons" *ngIf="!linksService.isReleaseVersionActive()">
    <sh-button
      [disabled]="!key || !title"
      size="m"
      (click)="save()"
      [label]="'general.save' | translate"
    ></sh-button>
    <sh-button
      color="secondary"
      (click)="cancel()"
      size="m"
      [label]="'general.cancel' | translate"
    ></sh-button>
  </div>
</div>
