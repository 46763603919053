import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { Router } from '@angular/router';
import { StepService } from './step.service';
import { LanguageService } from '../../../../../services/language.service';
import { PopoverService } from '../../../../../services/popover.service';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { WorkflowGuidesService } from '@backend/webapp/workflowguides/workflowguides.service';
import { ParamsService } from '@backend/webapp/services/params.service';
import { Constants } from '@backend/interfaces';
import { LocalizationService } from '@backend/webapp/shared/localization/localization.service';
import { pairwise } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
  selector: 'backend-steps',
  templateUrl: './steps.component.html',
  styleUrls: ['./steps.component.scss'],
})
export class StepsComponent implements OnInit, OnDestroy {
  public steps: any[];

  @ViewChildren('popover')
  public popovers: QueryList<ElementRef<HTMLElement>>;
  public globalLanguage = Constants.DEFAULT_GLOBAL_LANGUAGE;

  paramSubscription: Subscription;
  languageSubscription: Subscription;

  public constructor(
    public readonly languageService: LanguageService,
    public readonly popoverService: PopoverService,
    public readonly paramsService: ParamsService,
    public readonly stepService: StepService,
    public readonly router: Router,
    public readonly linksService: WorkflowGuidesService,
    public readonly localization: LocalizationService
  ) {}

  public async deleteStep(id: number, stepkey) {
    await this.stepService.deleteStep(id, stepkey);
    this.steps = await this.stepService.getSteps();
    this.linksService.loadPhaseLinks();
    this.linksService.loadWfLinks();
  }

  public async ngOnInit(): Promise<void> {
    this.steps = await this.stepService.getSteps();
    this.languageSubscription = this.localization.globalLanguage$.subscribe(
      async (data) => {
        if (this.router.isActive) {
          this.globalLanguage = data;
          this.steps = await this.stepService.getSteps();
        }
      }
    );
    this.paramSubscription = this.paramsService.keyActivityId$
      .pipe(pairwise())
      .subscribe(async ([previous, current]) => {
        if (previous != null && previous !== current && this.router.isActive) {
          this.steps = await this.stepService.getSteps();
        }
      });
  }

  ngOnDestroy() {
    if (this.paramSubscription) {
      this.paramSubscription.unsubscribe();
    }
    if (this.languageSubscription) {
      this.languageSubscription.unsubscribe();
    }
  }

  async drop(event: CdkDragDrop<string[]>): Promise<void> {
    await this.stepService.orderSteps(
      this.steps,
      event.previousIndex,
      event.currentIndex
    );
  }
}
