import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  from,
  Observable,
  ReplaySubject,
  throwError,
  BehaviorSubject,
  lastValueFrom,
} from 'rxjs';
import { catchError, concatMap, shareReplay, tap } from 'rxjs/operators';
import { IAdminUser } from '@backend/interfaces';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import {
  InteractionRequiredAuthError,
  PublicClientApplication,
  RedirectRequest,
  SilentRequest,
  AuthenticationResult,
} from '@azure/msal-browser';
import { ConfirmationService } from '../shared/confirmation/confirmation.service';
import { SpinnerService } from '../spinner/spinner.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  // public observable that emits value whenever there is user login state change
  loggedIn$: Observable<boolean>;

  // public observable of user profile data
  userProfile$: Observable<any>;

  private $roleSubject: BehaviorSubject<IAdminUser> = new BehaviorSubject({
    role: 0,
    name: '',
    firstName: '',
    lastName: '',
  });
  public get $role(): Observable<IAdminUser> {
    return this.$roleSubject.asObservable();
  }

  // Create an observable of Auth0 instance of client
  private msal$: Observable<PublicClientApplication>;
  private loggedInSubject$: ReplaySubject<boolean>;
  private userProfileSubject$: ReplaySubject<any>;

  public expiresOn: Date;

  constructor(
    private readonly router: Router,
    private readonly http: HttpClient,
    private readonly confirmation: ConfirmationService,
    private readonly spinner: SpinnerService
  ) {
    this.initialize();
    this.localAuthSetup();
  }

  getTokenSilently$(): Observable<any> {
    const tokenRequest: SilentRequest = {
      scopes: ['openid', 'email'],
    };

    return this.msal$.pipe(
      concatMap((client: PublicClientApplication) =>
        from(
          client
            .acquireTokenSilent(tokenRequest)
            .then((authenticationResult: AuthenticationResult) => {
              // Set the expiry date to validate the token while handling the request
              this.expiresOn = new Date(
                authenticationResult.account.idTokenClaims.exp * 1000
              );
              return authenticationResult.idToken;
            })
            .catch((error: any) => {
              if (error instanceof InteractionRequiredAuthError) {
                this.spinner.hideSpinner();
                this.confirmation
                  .confirmWarn(
                    'Session Expired',
                    'Your Login session has expired! To continue working, please login again.'
                  )
                  .then((doLogin) => {
                    if (doLogin) {
                      this.logout();
                      this.login();
                      this.redirectAfterLogin();
                    }
                  });
                return this.msal$.pipe(
                  concatMap((client: PublicClientApplication) =>
                    from(client.acquireTokenRedirect(tokenRequest))
                  )
                );
              }
            })
        )
      )
    );
  }

  /**
   * Only the callback component should call this method.
   *
   * Call when app reloads after user logs in with Auth0
   */
  handleAuthCallback(): Observable<AuthenticationResult> {
    return this.msal$.pipe(
      concatMap((client: PublicClientApplication) =>
        client.handleRedirectPromise()
      ),
      tap((authenticationResult: AuthenticationResult) => {
        this.localAuthSetup();
        this.router.navigate(['/']);
      })
    );
  }

  login(): void {
    const loginRequest: RedirectRequest = {
      scopes: ['openid', 'email'],
    };
    this.msal$.subscribe((client: PublicClientApplication) => {
      client.handleRedirectPromise();
      client.loginRedirect(loginRequest);
    });
  }

  logout(): void {
    this.msal$.subscribe((client: PublicClientApplication) => {
      client.handleRedirectPromise();
      client.logoutRedirect();
    });
  }

  /**
   * Returns the user information if available (decoded from the id_token).
   *
   * Refer https://auth0.github.io/auth0-spa-js/classes/auth0client.html#getuser for more information.
   */
  private getUser$(): Observable<any> {
    return this.msal$.pipe(
      concatMap((client: PublicClientApplication) => client.getAllAccounts()),
      tap((userProfile: any) => {
        this.userProfileSubject$.next(userProfile);
      })
    );
  }

  private async setRole(name: string) {
    const user = await lastValueFrom(
      this.http.get<IAdminUser>('/api/adminusers/find?name=' + name)
    );
    if (user && user.role >= 1) {
      this.$roleSubject.next(user);
    } else {
      this.logout();
    }
  }

  private initialize(): void {
    this.msal$ = from(
      new Promise((resolve) => {
        resolve(
          new PublicClientApplication({
            auth: {
              clientId: environment.client_id,
              authority: environment.authority,
              redirectUri: `${window.location.origin}/callback`,
              postLogoutRedirectUri: `${window.location.origin}/login`,
              navigateToLoginRequestUrl: false,
            },
            cache: {
              cacheLocation: 'sessionStorage',
              storeAuthStateInCookie: false,
            },
          })
        );
      })
    ).pipe(shareReplay(1), catchError(throwError as any));

    this.userProfileSubject$ = new ReplaySubject<any>(1);
    this.loggedInSubject$ = new ReplaySubject<boolean>(1);

    this.userProfile$ = this.userProfileSubject$.asObservable();
    this.loggedIn$ = this.loggedInSubject$.asObservable();
  }

  public isAuthenticated$(): Observable<boolean> {
    return this.msal$.pipe(
      concatMap((client: PublicClientApplication) => this.checkAccount(client))
    );
  }

  private async checkAccount(
    client: PublicClientApplication
  ): Promise<boolean> {
    if (client.getAllAccounts().length > 0) {
      client.setActiveAccount(client.getAllAccounts()[0]);
      return true;
    } else {
      return false;
    }
  }

  /**
   * Sets up isAuthenticated$ and userProfile$ locally.
   *
   * Should be called on page refresh as well as after user has logged in.
   */
  private localAuthSetup(): void {
    this.isAuthenticated$()
      .pipe(
        tap((loggedIn: boolean) => {
          this.loggedInSubject$.next(loggedIn);
        })
      )
      .subscribe((loggedIn: boolean) => {
        if (loggedIn) {
          this.getUser$().subscribe((outh0User: any) => {
            this.setRole(outh0User.idTokenClaims.email);
          });
        }
      });
  }

  public redirectAfterLogin() {
    this.initialize();
    this.localAuthSetup();
    this.router.navigate(['/'], { replaceUrl: true });
  }
}
