import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, lastValueFrom, Observable } from 'rxjs';
import { ParamsService } from '../../services/params.service';
import { WorkflowGuidesService } from '../workflowguides.service';
import { ConfirmationService } from '@backend/webapp/shared/confirmation/confirmation.service';
import { PhaseVersionService } from './phase-versions/phase-version.service';

@Injectable({
  providedIn: 'root',
})
export class PhaseService {
  private phasenSubject: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(
    []
  );

  public get phasen(): Observable<any[]> {
    return this.phasenSubject.asObservable();
  }

  public constructor(
    private readonly http: HttpClient,
    private readonly paramsService: ParamsService,
    private readonly linksService: WorkflowGuidesService,
    private confirmationService: ConfirmationService,
    private readonly phaseVersionService : PhaseVersionService
  ) {
    this.loadPhaseWrappers();
  }

  public async createPhaseWrapper(key: string, name: string, copyFrom: any,versionDescription : string, ar : boolean) {
    this.paramsService.isLoading = true;
    let result;
    try {
      result = await lastValueFrom(
        this.http.post('/api/workflowguides/phases', { key, name })
      );
      if(copyFrom > 1)
      {
         this.phaseVersionService.createPhaseVersion({copyFrom : copyFrom,versionDescription: versionDescription,ar: ar,phaseWrapperId: result.id},null)
       }
      this.paramsService.isLoading = false;
      await this.loadPhaseWrappers();
      return true;
    } catch (e) {
      return false;
    } finally {
      this.paramsService.isLoading = false;
    }
    
  }

  public async deletePhaseWrapper(id: number, phaseWrapper) {
    if (
      await this.confirmationService.confirmDelete(
        'workflowguides.phases.phaseWrappers.delete',
        'workflowguides.phases.phaseWrappers.deleteConfirmation',
        { phaseWrapper }
      )
    ) {
      this.paramsService.isLoading = true;
      try {
        await lastValueFrom(
          this.http.delete('/api/workflowguides/phases/' + id)
        );
        await this.loadPhaseWrappers();
        return true;
      } catch (e) {
        console.log(e);
        return false;
      } finally {
        this.paramsService.isLoading = false;
      }
    }
  }

  public async editPhaseWrapper(id: number, name: string) {
    this.paramsService.isLoading = true;
    await lastValueFrom(
      this.http.put('/api/workflowguides/phases/' + id, { name })
    );
    this.paramsService.isLoading = false;
    await this.loadPhaseWrappers();
    return true;
  }

  public async getPhaseWrapper(id: number = this.paramsService.phaseWrapperId) {
    if (id) {
      this.paramsService.isLoading = true;
      const result = await lastValueFrom(
        this.http.get('/api/workflowguides/phases/' + id)
      );
      this.paramsService.isLoading = false;
      return result;
    }
    return null;
  }

  public async getAllVersions() {
    this.paramsService.isLoading = true;
    const result = await lastValueFrom(
      this.http.get<any[]>('/api/workflowguides/phases/-/versions/all')
    );
    this.paramsService.isLoading = false;
    return result;
  }

  public async loadPhaseWrappers() {
    this.paramsService.isLoading = true;
    this.phasenSubject.next(
      await lastValueFrom(this.http.get<any[]>('/api/workflowguides/phases'))
    );
    this.paramsService.isLoading = false;
    await this.linksService.loadPhaseLinks();
    return true;
  }
}
