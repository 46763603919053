import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { PhaseVersionService } from '../phase-version.service';
import { Router } from '@angular/router';
import { LanguageService } from '../../../../services/language.service';
import { ParamsService } from '../../../../services/params.service';
import { Status } from '@backend/interfaces';
import { Observable, Subscription } from 'rxjs';
import { PhaseService } from '../../phase.service';
import { SpinnerService } from 'apps/webapp/src/app/spinner/spinner.service';
import { ImgConvertorComponent } from '@backend/webapp/img-convertor/img-convertor.component';
import { WorkflowGuidesService } from '@backend/webapp/workflowguides/workflowguides.service';
import { Constants } from '@backend/interfaces';
import { LocalizationService } from '@backend/webapp/shared/localization/localization.service';
import { GetLocaleTextPipe } from '@backend/webapp/get-locale-text.pipe';
import { pairwise } from 'rxjs/operators';

@Component({
  selector: 'backend-edit-phase-version',
  templateUrl: './edit-phase-version.component.html',
  styleUrls: ['./edit-phase-version.component.scss'],
})
export class EditPhaseVersionComponent implements OnInit, OnDestroy {
  @ViewChild('previewImage')
  public previewImage: ElementRef<HTMLImageElement>;

  public isEditMode = false;

  public id: number;
  // public key: string;
  public name: string;
  public description: string;
  public version: number;
  public versionDescription: string;
  public ar: boolean;
  public arInitial: boolean;
  public status: string;

  public statuus: string[] = Object.keys(Status).filter((k) =>
    Number.isNaN(Number(k))
  );

  public thumbnail: string;
  public thumbnailBasename: string;

  public thumbnailChanged = false;

  public section = 'keyActivities';
  public keyActivities: any[];

  private thumbnailFile: File;

  public copyFrom = null;
  public phases: Observable<any[]>;
  private imgConvertor: ImgConvertorComponent;

  public globalLanguage = Constants.DEFAULT_GLOBAL_LANGUAGE;
  nameTrans: any[];
  descriptionTrans: any[];

  paramSubscription: Subscription;
  languageSubscription: Subscription;

  public constructor(
    private readonly router: Router,
    private readonly phaseVersionService: PhaseVersionService,
    private readonly paramsService: ParamsService,
    public readonly languageService: LanguageService,
    private readonly renderer: Renderer2,
    private readonly phaseService: PhaseService,
    private spinnerService: SpinnerService,
    public readonly linksService: WorkflowGuidesService,
    public readonly localization: LocalizationService
  ) {
    this.imgConvertor = new ImgConvertorComponent();
    this.section = 'keyActivities';
  }

  public async ngOnInit(): Promise<void> {
    this.resetFields();
    this.updateFields();
    this.languageSubscription = this.localization.globalLanguage$.subscribe(
      async (data) => {
        if (this.router.isActive) {
          this.globalLanguage = data;
          this.resetFields();
          await this.updateFields();
        }
      }
    );
    this.paramSubscription = this.paramsService.phaseVersionId$
      .pipe(pairwise())
      .subscribe(async ([previous, current]) => {
        if (previous != null && previous !== current && this.router.isActive) {
          this.resetFields();
          await this.updateFields();
        }
      });
  }

  ngOnDestroy() {
    if (this.paramSubscription) {
      this.paramSubscription.unsubscribe();
    }
    if (this.languageSubscription) {
      this.languageSubscription.unsubscribe();
    }
  }

  private async updateFields(): Promise<void> {
    const phaseVersion =
      (await this.phaseVersionService.getPhaseVersion()) as any;
    if (phaseVersion) {
      this.section = 'keyActivities';
      this.name = this.languageService.getTranslationByKey(
        phaseVersion.name,
        this.globalLanguage.key
      );
      this.nameTrans = new GetLocaleTextPipe(this.localization).transform(
        phaseVersion.name,
        this.globalLanguage.key
      );
      this.description = this.languageService.getTranslationByKey(
        phaseVersion.description,
        this.globalLanguage.key
      );
      this.descriptionTrans = new GetLocaleTextPipe(
        this.localization
      ).transform(phaseVersion.description, this.globalLanguage.key);
      this.version = phaseVersion.version;
      this.versionDescription = phaseVersion.versionDescription;
      this.ar = this.arInitial = phaseVersion.ar;
      this.status = Status[phaseVersion.status];
      this.thumbnail = phaseVersion.thumbnail;
      this.id = phaseVersion.id;

      if (this.thumbnail) {
        this.thumbnailBasename = this.thumbnail.replace(/.*[\/\\]/, '');
      } else {
        this.thumbnailBasename = '';
      }

      this.isEditMode = true;
    } else {
      this.section = 'base';
      this.resetFields();
      this.isEditMode = false;
    }
  }

  private resetFields(): void {
    this.name =
      this.description =
      this.versionDescription =
      this.thumbnail =
        '';
    this.version = null;
    this.ar = this.arInitial = false;
    this.status = Status[Status.DRAFT];
    this.phaseService.loadPhaseWrappers();
    this.phases = this.phaseService.phasen;
    this.thumbnailChanged = false;
  }

  public async save() {
    const file = this.thumbnailChanged
      ? this.imgConvertor.getImageFile()
      : null;
    let phase: any;
    if (!this.isEditMode) {
      phase = await this.phaseVersionService.createPhaseVersion(
        {
          versionDescription: this.versionDescription,
          ar: this.ar,
          copyFrom: this.copyFrom,
        },
        file
      );
    } else {
      phase = await this.phaseVersionService.editPhaseVersion(
        this.id,
        {
          version: this.version,
          versionDescription: this.versionDescription,
          status: Status[this.status], // to number!
          ar: this.ar,
        },
        file
      );
    }

    this.spinnerService.showSpinner();

    if (
      this.description !==
      this.languageService.getTranslationByKey(
        phase.description,
        this.globalLanguage.key
      )
    )
      await this.phaseVersionService.editDescription(
        phase.id,
        this.description
      );

    if (
      this.name !==
      this.languageService.getTranslationByKey(
        phase.name,
        this.globalLanguage.key
      )
    )
      await this.phaseVersionService.editName(phase.id, this.name);

    await this.phaseVersionService.loadPhaseVersions();

    this.spinnerService.hideSpinner();

    if (phase) {
      this.goBack();
      this.linksService.loadPhaseLinks();
      this.linksService.loadWfLinks();
    }
  }

  public async cancel() {
    await this.goBack();
  }

  public async goBack() {
    if (this.router.url.indexOf('/workflows') !== -1) {
      await this.router.navigate([
        'workflowguides',
        'workflows',
        this.paramsService.workflowId,
        'versions',
        this.paramsService.workflowVersionId,
      ]);
    } else {
      await this.router.navigate([
        'workflowguides',
        'phases',
        this.paramsService.phaseWrapperId,
        'versions',
      ]);
    }
  }

  public fileChange(file: File): void {
    if (file) {
      this.thumbnail = file.name;
      this.thumbnailFile = file;
      this.thumbnailBasename = file.name.replace(/.*[\/\\]/, '');
      this.thumbnailChanged = true;

      const reader = new FileReader();
      reader.onloadend = (e) => {
        let image = new Image();
        this.renderer.setAttribute(
          this.previewImage.nativeElement,
          'src',
          e.target.result.toString()
        );
        image.src = e.target.result.toString();
        image.onload = () => {
          this.imgConvertor.drawImageOnCanvas(image, this.thumbnailBasename);
        };
      };
      reader.readAsDataURL(file);
    } else {
      this.thumbnail = '';
      this.thumbnailBasename = '';
    }
  }
}
