import { Component, OnDestroy, OnInit } from '@angular/core';
import { OverviewsService } from '@backend/webapp/workflowguides/workflows/workflow-versions/overviews/overviews.service';
import { Overview } from '@backend/webapp/workflowguides/workflows/workflow-versions/overviews/overview';
import { Router } from '@angular/router';
import { ParamsService } from '@backend/webapp/services/params.service';
import { LocalizationService } from '@backend/webapp/shared/localization/localization.service';
import { Constants } from '@backend/interfaces';
import { pairwise } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { WorkflowGuidesService } from '@backend/webapp/workflowguides/workflowguides.service';

@Component({
  selector: 'backend-overviews',
  templateUrl: './overviews.component.html',
  styleUrls: ['./overviews.component.scss'],
})
export class OverviewsComponent implements OnInit, OnDestroy {
  overviews: Overview[];
  public globalLanguage = Constants.DEFAULT_GLOBAL_LANGUAGE;

  paramSubscription: Subscription;
  languageSubscription: Subscription;

  constructor(
    private overviewsService: OverviewsService,
    private readonly paramsService: ParamsService,
    private router: Router,
    public readonly localization: LocalizationService,
    public workflowGuidesService: WorkflowGuidesService
  ) {}

  async ngOnInit(): Promise<void> {
    this.overviews = await this.overviewsService.getAllOverviews();
    this.languageSubscription = this.localization.globalLanguage$.subscribe(
      async (data) => {
        if (this.router.isActive) {
          this.globalLanguage = data;
          this.overviews = await this.overviewsService.getAllOverviews();
        }
      }
    );
    this.paramSubscription = this.paramsService.workflowVersionId$
      .pipe(pairwise())
      .subscribe(async ([previous, current]) => {
        if (previous != null && previous !== current && this.router.isActive) {
          this.overviews = await this.overviewsService.getAllOverviews();
        }
      });
  }

  ngOnDestroy() {
    if (this.paramSubscription) {
      this.paramSubscription.unsubscribe();
    }
    if (this.languageSubscription) {
      this.languageSubscription.unsubscribe();
    }
  }

  showOverview(id: number) {
    this.router.navigate(['overviews', id]).then();
  }

  async delete(overview: Overview): Promise<void> {
    await this.overviewsService.delete(overview.id, overview.title);
    this.overviews = await this.overviewsService.getAllOverviews();
  }
}
