import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { WorkflowVersionService } from '../workflow-version.service';
import { Router } from '@angular/router';
import { LanguageService } from '../../../../services/language.service';
import { ParamsService } from '../../../../services/params.service';
import { Status } from '@backend/interfaces';
import { PhaseService } from '../../../phases/phase.service';
import { Observable, Subscription } from 'rxjs';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { WorkflowService } from '../../workflow.service';
import { WorkflowGuidesService } from '@backend/webapp/workflowguides/workflowguides.service';
import { PopoverService } from '@backend/webapp/services/popover.service';
import { Constants } from '@backend/interfaces';
import { LocalizationService } from '@backend/webapp/shared/localization/localization.service';
import { pairwise } from 'rxjs/operators';
import { DeviceVersionsService } from '@backend/webapp/devices/device-versions/device-versions.service';
import { SpinnerService } from '@backend/webapp/spinner/spinner.service';
import { DevicesService } from '@backend/webapp/devices/devices.service';

@Component({
  selector: 'backend-edit-workflow-version',
  templateUrl: './edit-workflow-version.component.html',
  styleUrls: ['./edit-workflow-version.component.scss'],
})
export class EditWorkflowVersionComponent implements OnInit, OnDestroy {
  @ViewChildren('popover')
  public popovers: QueryList<ElementRef<HTMLElement>>;

  public isEditMode = false;

  public id: number;
  public name: string;
  public dropdown: string;
  public version: number;
  public appVersion: number;
  public status: string;
  public assignedPhases: boolean;
  public unassignedPhase: boolean;
  public arSupportInitial: boolean;
  public arSupport: boolean;
  public forceUpdate: boolean;
  public AgshowHeaderSave: boolean;
  public simulationProduct: string;
  public simulationScene: string;
  public statuus: string[] = Object.keys(Status).filter((k) =>
    Number.isNaN(Number(k))
  );
  public section = 'phases';
  public phases: any[];
  public phaseArray = [];
  public assignedPhasearray = [];
  public deviceVersion: number;
  public deviceVersionDetails: any;
  public copyworkflowDetails: any;
  public copyFrom = null;
  public unassignedPhasearray = [];

  public allPhaseVersions: any[];
  public deviceValues: any[];
  public deviceVersions: Observable<any[]>;
  public workflows: Observable<any[]>;

  public globalLanguage = Constants.DEFAULT_GLOBAL_LANGUAGE;
  titleTrans: any[];
  descriptionTrans: any[];

  paramSubscription: Subscription;
  languageSubscription: Subscription;
  showReleaseWorkflowModal: boolean = false;

  public constructor(
    public readonly router: Router,
    private readonly workflowVersionService: WorkflowVersionService,
    private readonly paramsService: ParamsService,
    private readonly phasesService: PhaseService,
    public readonly languageService: LanguageService,
    public readonly popoverService: PopoverService,
    private readonly deviceVersionService: DeviceVersionsService,
    private spinnerService: SpinnerService,
    public readonly linksService: WorkflowGuidesService,
    public readonly localization: LocalizationService,
    public readonly devicesService: DevicesService,
    public readonly workflowService: WorkflowService,
    public readonly workflowGuidesService: WorkflowGuidesService
  ) {
    this.section = 'phases';
  }

  public async ngOnInit(): Promise<void> {
    this.resetFields();
    this.deviceVersions = this.deviceVersionService.devicesWithVersions$;
    this.deviceVersions.subscribe((devices: any[]) => {
      this.deviceValues = devices;
    });
    this.allPhaseVersions = await this.phasesService.getAllVersions();
    await this.updateFields();
    this.AgshowHeaderSave = true;

    if (this.phases.length > 0) {
      this.assignedPhases = true;
      this.unassignedPhase = false;
      this.dropdown = 'Assigned Phases';
    } else {
      this.unassignedPhase = true;
      this.assignedPhases = false;
      this.dropdown = 'Unassigned Phases';
    }
    this.checkboxPhases();
    this.languageSubscription = this.localization.globalLanguage$.subscribe(
      async (data) => {
        if (this.router.isActive) {
          this.globalLanguage = data;
          await this.updateFields();
        }
      }
    );
    this.paramSubscription = this.paramsService.workflowVersionId$
      .pipe(pairwise())
      .subscribe(async ([previous, current]) => {
        if (previous != null && previous !== current && this.router.isActive) {
          await this.updateFields();
        }
      });
  }

  ngOnDestroy() {
    if (this.paramSubscription) {
      this.paramSubscription.unsubscribe();
    }
    if (this.languageSubscription) {
      this.languageSubscription.unsubscribe();
    }
  }

  private async updateFields(): Promise<void> {
    this.spinnerService.showSpinner();
    const workflowVersion =
      (await this.workflowVersionService.getWorkflowVersion()) as any;
    this.spinnerService.hideSpinner();
    if (workflowVersion) {
      this.section = 'phases';
      this.id = workflowVersion.id;
      this.name = workflowVersion.name;
      this.version = workflowVersion.version;
      this.arSupportInitial = this.arSupport = workflowVersion.arSupport;
      this.forceUpdate = workflowVersion.forceUpdate;
      this.simulationProduct = workflowVersion.simulationProduct;
      this.simulationScene = workflowVersion.simulationScene;
      this.appVersion = workflowVersion.appVersion;
      this.phases = [];
      this.status = Status[workflowVersion.status];
      if (workflowVersion.phaseOrder.length === 0) {
        workflowVersion.phasen.sort((a, b) => a.id - b.id);
        this.phases = workflowVersion.phasen;
      } else {
        workflowVersion.phaseOrder.sort((a, b) => a.order - b.order);
        workflowVersion.phaseOrder.forEach((element) => {
          this.phases.push(element.phase);
        });
      }
      this.deviceVersion = workflowVersion.deviceVersion?.id;
      this.isEditMode = true;

      if (this.phases?.length > 0) {
        this.phases.forEach((p) => (p.referenced = true));
        this.allPhaseVersions.forEach(
          (phase) =>
            (phase.referenced =
              this.phases.find((p) => p.id === phase.id) != null)
        );
      }
    } else {
      this.section = 'base';
      this.resetFields();
      this.workflowService
        .loadWorkflows()
        .then(() => (this.workflows = this.workflowService.workflows));
      this.isEditMode = false;
    }
    this.deviceVersionDetails = await this.returnDeviceDetails();
  }
  public async returnDeviceDetails() {
    let details;
    this.deviceValues.forEach((a) => {
      if (a.deviceVersions.length < 1) {
        if (a.id === this.deviceVersion) {
          details = this.languageService.getTranslationByKey(
            a.deviceDescription.name,
            this.globalLanguage.key
          );
        }
      } else {
        a.deviceVersions.forEach((b) => {
          if (b.id === this.deviceVersion) {
            details =
              this.languageService.getTranslationByKey(
                a.deviceDescription.name,
                this.globalLanguage.key
              ) +
              ' ' +
              (b.status === Status.DRAFT ? 'Draft' : 'Release');
          }
        });
      }
    });
    return details;
  }

  private resetFields(): void {
    this.name = '';
    this.version = null;
    this.appVersion = null;
    this.arSupportInitial = this.arSupport = false;
    this.forceUpdate = false;
    this.simulationProduct = '';
    this.simulationScene = '';
    this.status = Status[Status.DRAFT];
    this.phases = [];
    this.workflows = this.workflowService.workflows;
  }
  public setWorkflowVersionId(versionId: any) {
    this.copyFrom = versionId;
  }
  setDeviceVersionId(id: any, value: any) {
    this.deviceVersionDetails = value;
    this.deviceVersion = id;
  }
  public async save() {
    if (this.deviceVersion === undefined || this.deviceVersion == null) {
      alert('Device Version is Missing');
      return;
    }
    let result: any;
    if (!this.isEditMode) {
      result = await this.workflowVersionService.createWorkflowVersion({
        arSupport: this.arSupport,
        status: Status.DRAFT,
        deviceVersion: this.deviceVersion,
        copyFrom: this.copyFrom,
        name: this.titleTrans
      });
    } else {
      const phaseVersions = this.allPhaseVersions.filter((pv) => pv.referenced);
      const phaseSelect = this.phases.filter((ph) => ph.referenced === false);
      result = await this.workflowVersionService.editWorkflowVersion({
        id: this.id,
        arSupport: this.arSupport,
        status: Status[this.status], // number!
        deviceVersion: this.deviceVersion
      });
      result = await this.edit(result, phaseVersions, phaseSelect);
      this.spinnerService.hideSpinner();
    }

    if (result) {
      this.gotoWorkflowVersions();
      this.linksService.loadWfLinks();
    }
  }

  async edit(result, phaseVersions, phaseSelect) {
    let phasever = [];
    result.phasen = result.phasen ?? [];
    if (result.phaseOrder.length === 0) {
      phasever = result.phasen;
    } else {
      result.phaseOrder.forEach((element) => {
        phasever.push(element.phase);
      });
    }

    for (const phaseVersion of phasever) {
      if (phaseSelect.find((pv) => pv.id === phaseVersion.id)) {
        // old phase version no longer referenced -> delete!
        await this.workflowVersionService.removePhaseVersionToCurrent(
          phaseVersion.id
        );
      }
    }

    for (const phaseVersion of phaseVersions) {
      if (!phasever.find((pv) => pv.id === phaseVersion.id)) {
        await this.workflowVersionService.addPhaseVersionToCurrent(
          phaseVersion.id
        );
      }
    }
    return result;
  }
  public async cancel() {
    this.gotoWorkflowVersions();
  }

  public gotoWorkflowVersions(): void {
    this.router
      .navigate([
        'workflowguides',
        'workflows',
        this.paramsService.workflowId,
        'versions',
      ])
      .then();
  }

  public hasPhase(phase: any): boolean {
    if (this.phases == null || this.phases.length === 0) return false;
    return this.phases.find((p) => p.id === phase.id) != null;
  }

  async drop(event: CdkDragDrop<string[]>): Promise<void> {
    if (this.assignedPhases) {
      await this.workflowVersionService.orderPhaseActivities(
        this.phases,
        this.id,
        event.previousIndex,
        event.currentIndex
      );
      this.linksService.loadWfLinks();
    }
  }

  public checkboxPhases() {
    this.phases.forEach((ph) => {
      if (ph.key != null) {
        this.phaseArray.push(ph.key);
      }
    });
    this.allPhaseVersions = this.allPhaseVersions.filter(
      (item) => !this.phaseArray.includes(item.key)
    );
  }
  public selectPhase(phase: any) {
    this.phases.forEach((ph) => {
      if (phase.id === ph.id) {
        phase.referenced = !phase.referenced;
        this.allPhaseVersions.push(phase);
      }
    });
  }

  getPhaseWrapperId(phaseVersionKey: string): string {
    let phases;
    this.linksService.phaselinks.subscribe((data) => {
      phases = data.filter((phase) => phase.key === phaseVersionKey);
    });
    return phases[0] ? phases[0].id : null;
  }

  public async releaseWorkflow(): Promise<void> {
    this.showReleaseWorkflowModal = false;
    const isWorkflowReleased = await this.workflowVersionService.editWorkflowVersion({
      id: this.id,
      arSupport: this.arSupport,
      status: Status.RELEASE,
      deviceVersion: this.deviceVersion
    });
    if (isWorkflowReleased) {
      await this.workflowVersionService.addWorkflowToPackageUsers(this.paramsService.workflowId);
    }
    window.location.reload();
  }

  public async createWorkflowVersion(): Promise<void> {
    let selectedWorkflow = this.workflowGuidesService.getCurrentWorkFlow();
    if (selectedWorkflow.workflowDraftVersion) {
      this.router.navigate([
        'workflowguides/workflows',
        selectedWorkflow.id,
        'versions',
        selectedWorkflow.workflowDraftVersion.id
      ]);
    } else {
      await this.workflowVersionService.createWorkflowVersion({
        arSupport: this.arSupport,
        status: Status.DRAFT,
        deviceVersion: this.deviceVersion,
        copyFrom: this.paramsService.workflowVersionId,
        name: selectedWorkflow.key
      });
      await this.workflowService.loadWorkflows();
      selectedWorkflow = this.workflowGuidesService.getCurrentWorkFlow();
      this.router.navigate([
        'workflowguides/workflows',
        this.paramsService.workflowId,
        'versions',
        selectedWorkflow.workflowDraftVersion.id
      ]);
    }
  }
}
